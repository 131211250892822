import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./plugins/i18n";
import axios from "axios";

//-------------------------------
//----------- axios -------------
//-------------------------------
import {
    NEW_ERRORMESSAGES,
    CLOSE,
} from "./store/modules/headerNotification/actions";
import { HIDE } from "./store/modules/loadingSpinner/actions";
import { LOGOUT } from "@/areas/account/store/actions";
import { ACCOUNT_LOGIN } from "@/router";

/** Behandlung von Requests zu axios-Calls
 */
axios.interceptors.request.use(
    async (request) => {
        //Allfällige Fehlermeldung eines früheren Requests entfernen
        store.dispatch("headerNotification/" + CLOSE, { root: true });

        request.headers["X-Requested-With"] = "XMLHttpRequest";

        console.info(`API ${request.method} ${request.url}`, request.data);
        return request;
    },
    (error) => {
        return Promise.reject(error);
    }
);

/** Behandlung von Responses zu axios-Calls
 */
axios.interceptors.response.use(
    /** Behandelt generell eine erhaltene API-Response */
    (Response) => {
        console.debug("API response ", {
            for: Response.config.url,
            response: Response,
        });

        //Prüfen, ob die API einen Request (möglicherweise aufgrund Session Timeout) mit einem Redirect auf eine Login-Page (HTML) beantwortet.
        if (Response.headers) {
            const contentType = Response.headers["content-type"];
            if (contentType) {
                if (contentType.includes("text/html")) {
                    if (Response.status === 200 /* OK*/) {
                        console.debug(
                            "router.currentRoute",
                            router.currentRoute
                        );

                        //Spezialfälle mit erwarteten HTML-Antworten ausscheiden
                        if (
                            Response.config.url.includes(
                                "DataSetPrintableView.aspx"
                            )
                        ) {
                            return Response;
                        }
                        if (Response.config.url.includes("/account/logout")) {
                            return Response;
                        }

                        if (router.currentRoute.name !== ACCOUNT_LOGIN) {
                            //Clientseitig das Logout und Redirect nachführen
                            console.log(
                                "Possible HTML for login page received, now redirecting to client login page"
                            );
                            store.dispatch("account/" + LOGOUT).then(() => {
                                router.push({ name: ACCOUNT_LOGIN });
                            });
                        }
                    }
                }
            }
        }
        return Response;
    },

    /** Behandelt den Fehlerfall */
    (error) => {
        store.dispatch("loadingSpinner/" + HIDE, {}, { root: true });

        //Hinweis: Für Controllers in ERST wird serverseitig sichergestellt, dass bei API-Calls im Fehlerfall immer mindestens eine Message verfügbar ist.
        const errorMessageTexts = new Array(0);

        if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.errorMessages
        ) {
            for (const [key, value] of Object.entries(
                error.response.data.errorMessages
            )) {
                //Fehlermeldungen holen (Erwartungsgemäss werden Keys geliefert)
                const errorMessageKey = `${value}`;
                //Fehlermeldungen internationalisiert ausgeben, wenn möglich ("te" prüft auf Existenz)
                if (i18n.te(errorMessageKey)) {
                    //Internationalisierten Text ausgeben
                    errorMessageTexts.push(i18n.t(errorMessageKey));
                } else {
                    //Fallback: Vorhandene Message im Original ausgeben
                    errorMessageTexts.push(errorMessageKey);
                }
            }
        } else if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.code &&
            error.response.data.description
        ) {
            //Hier handelt es sich um einen HTTP-Statuscode mit Description
            errorMessageTexts.push(
                `${error.response.data.code} ${error.response.data.description}`
            );
        } else if (error && error.response && error.response.data) {
            //Hier handelt es sich um ein unbekanntes Objekt
            if (
                error.response.headers["content-type"].startsWith("text/html")
            ) {
                //Ganze WebForms-HTML-Pages nicht ausgeben, sondern generisch melden
                errorMessageTexts.push(i18n.t("page_error_verarbeitung"));
            } else {
                //Ansonsten: Der Einfachheit halber einfach das Objekt anzeigen
                errorMessageTexts.push(error.response.data);
            }
        } else if (error && error.response) {
            //Fehlermeldungen holen (Erwartungsgemäss werden Keys geliefert)
            const errorMessageKey = error.response;
            //Fehlermeldungen internationalisiert ausgeben, wenn möglich ("te" prüft auf Existenz)
            if (i18n.te(errorMessageKey)) {
                //Internationalisierten Text ausgeben
                errorMessageTexts.push(i18n.t(errorMessageKey));
            } else {
                //Fallback: Vorhandene Message im Original ausgeben
                errorMessageTexts.push(errorMessageKey);
            }
        } else if (error && error.response && error.response.status >= 400) {
            // "Hässliche generelle BIT-Fehlerseiten" übersteuern mit generischer Fehlermeldung
            console.log(
                "HTML error page received, showing generic error message"
            );
        } else {
            console.debug("error", error);
            //Fehlermeldungen holen (Erwartungsgemäss werden Keys geliefert)
            const errorMessageKey = error;
            //Fehlermeldungen internationalisiert ausgeben, wenn möglich ("te" prüft auf Existenz)
            if (i18n.te(errorMessageKey)) {
                //Internationalisierten Text ausgeben
                errorMessageTexts.push(i18n.t(errorMessageKey));
            } else {
                //Fallback: Vorhandene Message im Original ausgeben
                errorMessageTexts.push(errorMessageKey);
            }
        }

        //Meldungen separiert anzeigen (Mehrzeilige Ausgabe mit // wird aktuell unterstüzt)
        store.dispatch(
            "headerNotification/" + NEW_ERRORMESSAGES,
            { errorMessages: errorMessageTexts.join(" // ") },
            { root: true }
        );

        return Promise.reject(error);
    }
);

//-------------------------------
//------------ Vue --------------
//-------------------------------
Vue.config.productionTip = false;

// Direktiven
import focus from "@/directives/focus-directive";
Vue.directive("focus", focus);

import PortalVue from "portal-vue";
Vue.use(PortalVue);

// VeeValidate
import VeeValidate from "@/plugins/VeeValidate";
import { INITIALIZE_LANGUAGE } from "./store/modules/resource/actions";
new VeeValidate();

store.dispatch("resource/" + INITIALIZE_LANGUAGE).then(() => {
    new Vue({
        router,
        store,
        i18n,
        render: (h) => h(App),
    }).$mount("#app");
});
