
import Vue from 'vue';
import { namespace } from 'vuex-class';

import { NAME as NAME_VALIDATION } from '@/store/modules/validation';
const validationModul = namespace(NAME_VALIDATION);

import { Component, Prop, Watch } from 'vue-property-decorator';
import Pikaday from 'pikaday';
import { GET_VALIDATION_KEY } from '@/store/modules/validation/getters';

@Component

/** Implementiert eine Textbox für eine Datumsangabe mit Label und kompletter Validierung
 * @remarks Rendert je eine Spalte für das Label und das Eingabefeld (sm-3 und sm-9 im Bootstrap-Grid)
 * @devdoc Verwendet die NPM Package pikaday (https://www.npmjs.com/package/pikaday) welche entweder per Javascript
 * aus dem swiss-styleguide oder als eigenständige Dependeny eingefügt werden muss
 */
export default class NowhowDatebox extends Vue {
    /** Validierung via Validations-Modul */
    @validationModul.Getter(GET_VALIDATION_KEY) getValidation!: (key: string) => string;

    @Prop({ required: true, type: String }) private id!: string;

    @Prop({ required: false, type: String, default: '' }) private label!: string;

    @Prop({ required: false, type: String, default: '' }) private placeholder!: string;

    @Prop({ required: false, type: String, default: '' }) private autocomplete!: string;

    @Prop({ required: false, type: Boolean, default: false }) private disabled!: boolean;

    @Prop({ required: false, type: Boolean, default: false }) private readonly!: boolean;

    /** Wenn festgelegt, verzögert die Validierung um die angegebene Anzahl Millisekunden
     * @devdoc Kann verwendet werden, um Probleme mit Layoutverschiebungen aufgrund von Validierungs-Meldungen bei
     * Mausklicks auf andere Elemente zu entschärfen
     * @devdoc  Siehe https://vee-validate.logaretm.com/v2/guide/components/validation-provider.html#props
     */
    @Prop({ required: false, type: Number, default: 0 }) private validationDelay!: number;

    @Prop({ default: '', type: [String, Object] }) validate!: string | object;

    @Prop({ required: true, default: '' }) private value!: string;

    @Prop({ required: false, type: String, default: '' })
    private cy!: string;

    /* eslint-disable-next-line no-empty, @typescript-eslint/no-empty-function */
    @Prop({ required: false, type: Function, default: function() {} })
    private onEnter!: Function;

    private provider: any;

    mounted() {
        //Initialwert zuweisen
        const htmlElement = document.getElementById(String(this.id));
        const htmlInputElement = htmlElement as HTMLInputElement;
        htmlInputElement.value = this.value;

        this.provider = this.$refs['provider'];

        //Pikaday initialisieren
        new Pikaday({
            field: htmlElement,
            format: 'DD.MM.yyyy',
            firstDay: 1, // sets monday as first day
            theme: 'admin-theme',
            i18n: {
                previousMonth: this.$t('allg_previous_month').toString(),
                nextMonth: this.$t('allg_next_month').toString(),
                months: [
                    this.$t('allg_january').toString(),
                    this.$t('allg_february').toString(),
                    this.$t('allg_march').toString(),
                    this.$t('allg_april').toString(),
                    this.$t('allg_may').toString(),
                    this.$t('allg_june').toString(),
                    this.$t('allg_july').toString(),
                    this.$t('allg_august').toString(),
                    this.$t('allg_september').toString(),
                    this.$t('allg_october').toString(),
                    this.$t('allg_november').toString(),
                    this.$t('allg_december').toString()
                ],
                weekdays: [
                    this.$t('allg_sunday').toString(),
                    this.$t('allg_monday').toString(),
                    this.$t('allg_tuesday').toString(),
                    this.$t('allg_wednesday').toString(),
                    this.$t('allg_thursday').toString(),
                    this.$t('allg_friday').toString(),
                    this.$t('allg_saturday').toString()
                ],
                weekdaysShort: [
                    this.$t('allg_sunday_short').toString(),
                    this.$t('allg_monday_short').toString(),
                    this.$t('allg_tuesday_short').toString(),
                    this.$t('allg_wednesday_short').toString(),
                    this.$t('allg_thursday_short').toString(),
                    this.$t('allg_friday_short').toString(),
                    this.$t('allg_saturday_short').toString()
                ]
            }
        });
    }

    public focus() {
        (this.$refs.inputfield as HTMLInputElement).focus();
    }

    private onPikadayChange(event: any) {
        console.debug('pikadayChange: ', event);
        this.$emit('change', event.target.value);
        //Das pikaday-change muss auch als input event ausgestossen werden, sonst wird es nicht korrekt weiterverarbeitet
        this.$emit('input', event.target.value);
    }

    private onChange(value) {
        this.$emit('change', value);
    }

    private onFocusout(value) {
        this.$emit('focusout', value);
    }

    private onInput(value) {
        this.$emit('input', value);
    }

    @Watch('value', { deep: true, immediate: true })
    private onValueChanged() {
        this.provider = this.$refs['provider'];
        this.provider?.validate(this.value, { silent: true });
    }
}
