import axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';
const CancelToken = axios.CancelToken;
const source = CancelToken.source();
export const axiosCancelToken = source;

export default class BaseService {
    protected BASE_URL: string | undefined = process.env.VUE_APP_BACKEND_URL;
    protected deleteConfig!: AxiosRequestConfig;
    protected getConfig!: AxiosRequestConfig;
    protected postConfig!: AxiosRequestConfig;
    protected putConfig!: AxiosRequestConfig;

    constructor() {
        if (!this.BASE_URL) {
            //Fallback für den Fall dass keine Backend-URL verfügbar ist
            const url = window.location.href;
            const searchParam = 'redesign/';
            const length = url.toLowerCase().indexOf(searchParam) + searchParam.length - 1;
            this.BASE_URL = url.substr(0, length);
            console.debug('baseService::constructor (with fallback URL)', this.BASE_URL);
        } else {
            console.debug('baseService::constructor', this.BASE_URL);
        }
    }

    /** Setzt einen GET-Request ab, mit typisiertem Rückgabewert (ohne Eingabedaten)
     * @param {string} url Die URL der Ressource
     * @param {AxiosRequestConfig} config Die zu verwendende Konfiguration; oder eine Standard-Konfiguration, wenn nicht angegeben
     * @returns {AxiosResponse<T>} Die Antwort der API
     * @devdoc Der Request enthält keine Eingabedaten, an den Axios-Request wird deshalb 'null' übergeben.
     */
    protected async GET<R>(url: string, config: AxiosRequestConfig = this.getGetConfig()) {
        const encodedUrl = encodeURI(url);
        return await axios.get<null, R>(encodedUrl, config);
    }

    /** Setzt einen GET-Request ab, mit typisiertem Rückgabewert und einem Identifikator als bigint
     * @param {string} url Die URL der Ressource
     * @param {bigint} id Die zu sendende Id, vom Typ bigint (Wird in die Route als '/{id}' eigefügt)
     * @param {AxiosRequestConfig} config Die zu verwendende Konfiguration; oder eine Standard-Konfiguration, wenn nicht angegeben
     * @returns {AxiosResponse<R>} Die Antwort der API
     */
    protected async GETbyId<R>(url: string, id: bigint, config: AxiosRequestConfig = this.getPostConfig()) {
        const encodedUrl = encodeURI(url);

        return await axios.get<string, R>(encodedUrl + '/' + id, config);
    }

    /** Setzt einen POST-Request ab, mit typisiertem Rückgabewert und typisierten Eingabedaten und einem Identifikator als bigint
     * @param {string} url Die URL der Ressource
     * @param {bigint} id Die zu sendende Id, vom Typ bigint (Wird in die Route als '/{id}' eigefügt)
     * @param {T} data Die zu sendenden Eingabedaten, vom Typ T
     * @param {AxiosRequestConfig} config Die zu verwendende Konfiguration; oder eine Standard-Konfiguration, wenn nicht angegeben
     * @returns {AxiosResponse<R>} Die Antwort der API
     * @devdoc Dieser Aufruf kann verwendet werden, um eine Resource mit Indikator zu aktualisieren und gleichzeitig allfällig serverseitig zusätzlich erfolgen Aktualisierungen zurückzuerhalten.
     */
    protected async POSTbyId<T, R>(
        url: string,
        id: bigint,
        data: T,
        config: AxiosRequestConfig = this.getPostConfig()
    ) {
        const encodedUrl = encodeURI(url);
        return await axios.post<T, R>(encodedUrl + '/' + id, data, config);
    }

    /** Setzt einen GET-Request ab, mit typisiertem Rückgabewert und einem Identifikator als string
     * @param {string} url Die URL der Ressource
     * @param {string} code Der zu sendende Identifikator/Code, vom Typ string (Wird in die Route als '/{id}' eigefügt)
     * @param {AxiosRequestConfig} config Die zu verwendende Konfiguration; oder eine Standard-Konfiguration, wenn nicht angegeben
     * @returns {AxiosResponse<T>} Die Antwort der API
     */
    protected async GETbyCode<R>(url: string, code: string, config: AxiosRequestConfig = this.getPostConfig()) {
        const encodedUrl = encodeURI(url);

        return await axios.get<string, R>(encodedUrl + '/' + code, config);
    }

    /** Setzt einen POST-Request ab, mit typisiertem Rückgabewert und typisierten Eingabedaten
     * @param {string} url Die URL der Ressource
     * @param {T} data Die zu sendenden Eingabedaten, vom Typ T
     * @param {AxiosRequestConfig} config Die zu verwendende Konfiguration; oder eine Standard-Konfiguration, wenn nicht angegeben
     * @returns {AxiosResponse<T>} Die Antwort der API
     */
    protected async POST<T, R>(url: string, data: T, config: AxiosRequestConfig = this.getPostConfig()) {
        const encodedUrl = encodeURI(url);

        return await axios.post<T, R>(encodedUrl, data, config);
    }

    // eslint-disable-next-line
    protected serializeObjectToQuerystring(obj: any) {
        return qs.stringify(obj, { addQueryPrefix: true, encode: false });
    }

    protected getDeleteConfig(): AxiosRequestConfig {
        return {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        };
    }

    protected getGetConfig(): AxiosRequestConfig {
        return {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        };
    }

    protected getPostConfig(): AxiosRequestConfig {
        return {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        };
    }

    protected getPutConfig(): AxiosRequestConfig {
        return {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            }
        };
    }
}
