import { ISwisstopoService } from './ISwisstopoService';
import BaseService from '@/services/baseService';
import { AxiosResponse } from 'axios';
import { IFeatureResult, IFeatureSearchResult } from '../api-viewmodel';

/**
 * Implementiert den Service für den Zugriff auf die Swisstopo-API
 * @class
 * @implements {ISwisstopoService}
 */
export class SwisstopoService extends BaseService implements ISwisstopoService {
    protected SWISSTOPO_BASE_URL: string | undefined = process.env.VUE_APP_SWISSTOPO_API_URL;

    /** Die URL für den Zugriff auf die SearchServer-Funktion */
    private SWISSTOPO_API_SEARCH_URL: string;

    /** Die URL für den Zugriff auf die MapServer-Funktion */
    private SWISSTOPO_API_MAP_URL: string;
    constructor() {
        super();

        if (!this.SWISSTOPO_BASE_URL) {
            //Fallback für den Fall dass keine API-URL verfügbar ist
            this.SWISSTOPO_BASE_URL = 'https://api3.geo.admin.ch/rest/services/api';
            console.debug('SwisstopoService::constructor (with fallback URL)', this.SWISSTOPO_BASE_URL);
        } else {
            console.debug('SwisstopoService::constructor', this.SWISSTOPO_BASE_URL);
        }

        this.SWISSTOPO_API_SEARCH_URL = `${this.SWISSTOPO_BASE_URL}/SearchServer`;
        this.SWISSTOPO_API_MAP_URL = `${this.SWISSTOPO_BASE_URL}/MapServer`;
    }

    /**
     * @inheritdoc
     */
    async searchFeatures(searchText: string): Promise<AxiosResponse<IFeatureSearchResult>> {
        const requestUrl = `${this.SWISSTOPO_API_SEARCH_URL}?searchText=${searchText}&type=featuresearch&features=ch.bfs.gebaeude_wohnungs_register`;
        //HINT Fehlerbehandlung prüfen mit absichtlichen Fehlern: const requestUrl = 'https://httpstat.us/500/';
        return super
            .GET<AxiosResponse<IFeatureSearchResult>>(requestUrl)
            .then((response: AxiosResponse<IFeatureSearchResult>) => {
                return response;
            });
    }

    /**
     * @inheritdoc
     */
    async getFeature(featureId: string): Promise<AxiosResponse<IFeatureResult>> {
        //Die geometrischen Koordinaten werden aktuell nicht verwendet. Sollten sie? Dann Abfrage ändern
        const requestUrl = `${this.SWISSTOPO_API_MAP_URL}/ch.bfs.gebaeude_wohnungs_register/${featureId}?returnGeometry=false`;
        return super.GET<AxiosResponse<IFeatureResult>>(requestUrl).then((response: AxiosResponse<IFeatureResult>) => {
            return response;
        });
    }
}

export const swisstopoService = new SwisstopoService();
