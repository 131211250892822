
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import { ACCOUNT_LOGIN, ACCOUNT_REGISTER } from '@/router';
@Component
export default class LeftNavigation extends Vue {
    private ACCOUNT_LOGIN = ACCOUNT_LOGIN;
    private ACCOUNT_REGISTER = ACCOUNT_REGISTER;
}
