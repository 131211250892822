
//Imports für dieses Modul
import Vue from 'vue';
import { namespace } from 'vuex-class';
import { Component, Watch } from 'vue-property-decorator';
import {
    ENTRY_WIZARD_INFO,
    ENTRY_WIZARD_CONTACT,
    ENTRY_WIZARD_ENTERPRISE,
    ENTRY_WIZARD_LOCAL,
    ENTRY_WIZARD_CONFIRMATION,
    ENTRY_WIZARD_ACTIVITY,
    ENTRY_WIZARD_CENSUS
} from '@/router';
import { NAME } from '../store/index';

const modul = namespace(NAME);

import { NAME as NAME_ACCOUNT } from '@/areas/account/store';
const accountModul = namespace(NAME_ACCOUNT);
import { IS_EXTERNAL_USER } from '@/areas/account/store/getters';

//Getters und Actions
import { GET_CASE_FULL_DATA, REMOVE_CASE_DATA } from '../store/actions';
import { CASE_FULL_DATA, CASE_STEPINFO, CASE_ID } from '../store/getters';
import { ICaseFullDataViewModel, ICaseStepInfoViewModel } from '@/api-viewmodel';

//Footer-Bar und -Buttons
import { NAME as NAME_FOOTERBAR } from '@/store/modules/footerBar';
const footerBarModul = namespace(NAME_FOOTERBAR);
import { CLOSE } from '@/store/modules/footerBar/actions';

export const InfoStepIndex = 0;
export const ContactStepIndex = 1;
export const EnterpriseStepIndex = 2;
export const LocalStepIndex = 3;
export const ActivityStepIndex = 4;
export const CensusStepIndex = 5;
export const ConfirmationStepIndex = 6;

@Component({
    components: {}
})
export default class Wizard extends Vue {
    //Wizard-Steps (für das Routing)
    private ENTRY_WIZARD_INFO = ENTRY_WIZARD_INFO;
    private ENTRY_WIZARD_CONTACT = ENTRY_WIZARD_CONTACT;
    private ENTRY_WIZARD_ENTERPRISE = ENTRY_WIZARD_ENTERPRISE;
    private ENTRY_WIZARD_LOCAL = ENTRY_WIZARD_LOCAL;
    private ENTRY_WIZARD_ACTIVITY = ENTRY_WIZARD_ACTIVITY;
    private ENTRY_WIZARD_CENSUS = ENTRY_WIZARD_CENSUS;
    private ENTRY_WIZARD_CONFIRMATION = ENTRY_WIZARD_CONFIRMATION;

    //Getters
    @modul.Getter(CASE_FULL_DATA) caseFullData!: ICaseFullDataViewModel;
    @modul.Getter(CASE_STEPINFO) caseStepInfo!: ICaseStepInfoViewModel;
    @modul.Getter(CASE_ID) caseId!: bigint;
    @accountModul.Getter(IS_EXTERNAL_USER) isExternalUser!: boolean;

    //Actions
    @modul.Action(GET_CASE_FULL_DATA) getCaseFullData!: Function;
    @modul.Action(REMOVE_CASE_DATA) removeCaseData!: Function;
    @footerBarModul.Action(CLOSE) closeFooterBar!: Function;

    /** Falldaten erst bei 'mounted' laden.
     * Die Falldaten müssen nur geladen werden, wenn diese auch angezeigt werden. */
    mounted() {
        console.debug('Wizard.vue::mounted');
        console.debug('Wizard.vue::mounted->query', this.$route.query);

        //Daten zum anzuzeigenden Fall holen
        if (this.isExternalUser) {
            //Für extere Benutzer immer automatisch serverseitig, anhand der Zuordnung via Benutzername holen
            console.debug('Wizard.vue::mounted->getCaseFullData für Externen Benutzer');
            this.getCaseFullData();
        } else {
            //Für BFS-Benutzer die Daten via URL-Query-Parameter holen (Bei Aufruf aus dem Backend)
            const caseId = this.$route.query.CaseId;
            if (caseId) {
                console.debug('Wizard.vue::mounted->getCaseFullData für BFS Benutzer mit Query:', caseId);
                this.getCaseFullData(caseId);
            } else {
                //Spezialfall: Für BFS-Benutzer, falls die Daten bereits vorhanden sind, nur die StepInfo anwenden
                //Dies behandelt den Spezialfall für die Rückkehr vom Kontakt-Screen
                console.debug('Wizard.vue::mounted->revisitByStepInfo für BFS Benutzer mit bestehenden Daten');
                this.revisitByStepInfo();
            }
        }
    }

    /** Behandelt die Änderung des Falls
     * @remarks Wenn die Basis-Fall-Daten erstmalig verfügbar sind oder falls der Fall ändert, die Step-Navigation ausführen
     * @devdoc Benhandelt den Spezialfall für das Printout eines Falls in einem Neuen Tab,
     * indem für diese Route nicht die Step-Navigation ausgeführt wird,
     * sondern effektiv die Printout-Route gepusht wird.
     */
    @Watch('caseFullData.caseId', { deep: true, immediate: true })
    private onCaseIdChanged(newVal: bigint, oldVal: bigint) {
        if (oldVal && newVal && newVal !== oldVal) {
            //Bei einem Wechsel von einem früheren Fall: bisherige Falldaten verwerfen
            console.debug('Wizard.vue::onCaseIdChanged->Change detected');
            this.removeCaseData();
        } else if (newVal && newVal !== oldVal) {
            console.debug('Wizard.vue::onCaseIdChanged->Case reload detected');
            //Fall neu geladen: jetzt Navigation gemäss der allfälligen Step-Info durchführen
            this.revisitByStepInfo();
        }
    }

    private revisitByStepInfo() {
        console.debug('Wizard.vue::revisitByStepInfo::caseStepInfo', this.caseStepInfo);
        console.debug('Wizard.vue::revisitByStepInfo::caseId', this.caseId);
        if (this.caseStepInfo && this.caseStepInfo.overallStepIndex) {
            //Step-Navigation nur anhand des Overall-Step-Index
            if (this.caseStepInfo.overallStepIndex === InfoStepIndex) {
                this.$router.push({
                    name: ENTRY_WIZARD_INFO
                });
            } else if (this.caseStepInfo.overallStepIndex === ContactStepIndex) {
                this.$router.push({
                    name: ENTRY_WIZARD_CONTACT
                });
            } else if (this.caseStepInfo.overallStepIndex === EnterpriseStepIndex) {
                this.$router.push({
                    name: ENTRY_WIZARD_ENTERPRISE
                });
            } else if (this.caseStepInfo.overallStepIndex === LocalStepIndex) {
                this.$router.push({
                    name: ENTRY_WIZARD_LOCAL
                });
            } else if (this.caseStepInfo.overallStepIndex === ActivityStepIndex) {
                this.$router.push({
                    name: ENTRY_WIZARD_ACTIVITY
                });
            } else if (this.caseStepInfo.overallStepIndex === CensusStepIndex) {
                this.$router.push({
                    name: ENTRY_WIZARD_CENSUS
                });
            } else if (this.caseStepInfo.overallStepIndex === ConfirmationStepIndex) {
                this.$router.push({
                    name: ENTRY_WIZARD_CONFIRMATION
                });
            }
        } else {
            //Fallback: Immer am Start beginnen
            this.$router.push({ name: ENTRY_WIZARD_INFO });
        }
    }

    /** Die von den Steps erstellten Wizard-Navigations-Buttons entfernen */
    beforeDestroy() {
        this.closeFooterBar();
        //Hinweis: Hier die Falldaten nicht entfernen (Der Benutzer könnte z.B. rasch auf den Kontakt-Screen wechseln)
    }
}
