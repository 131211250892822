import { MutationTree } from 'vuex';
import { IValidationState } from './state';

export const SET_RULES = 'setValidationRules';

export const mutations: MutationTree<IValidationState> = {
    [SET_RULES]: (state, value: Record<string, string>) => {
        state.validationRules = value;
    }
};
