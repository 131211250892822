import { MutationTree } from 'vuex';
import { ILoadingSpinnerState } from './state';

export const DELAY = 'delay';
export const TEXT = 'text';
export const VISIBLE = 'visible';

export const mutations: MutationTree<ILoadingSpinnerState> = {
    [DELAY](state, delay) {
        state.delay = delay;
    },
    [TEXT](state, text) {
        state.text = text;
    },
    [VISIBLE](state, visible) {
        state.visible = visible;
    }
};
