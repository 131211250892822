
import Vue from 'vue';
import { cloneDeep } from 'lodash';
import { Component, Prop } from 'vue-property-decorator';
import NowhowDropdown from '@/components/NowhowDropdown.vue';

import { ISelectListItem } from '@/types/clienttypes';

/** Implementiert ein Dropdown mit Label und kompletter Validierung für eine Ja/Nein/Null Auswahl
 * @remarks Rendert je eine Spalte für das Label und die Radio-Buttons (sm-3 und sm-9 im Bootstrap-Grid)
 * @devdoc Verwendet intern die NowhowDropdown Komponente
 */
@Component({
    components: { NowhowDropdown }
})
export default class NowhowDropdownYesNoNull extends Vue {
    @Prop({ required: true, type: String }) private id!: string;

    @Prop({ required: false, type: String }) private name!: string;

    @Prop({ required: false, type: String, default: '' }) private label!: string;

    @Prop({ required: false, type: String, default: 'yes' }) private labelYes!: string;

    @Prop({ required: false, type: String, default: 'no' }) private labelNo!: string;

    @Prop({ required: false, type: String, default: '' }) private labelNull!: string;

    @Prop({ required: false, type: Boolean, default: false }) private disabled!: boolean;

    @Prop({ required: false, type: Boolean, default: false }) private readonly!: boolean;

    @Prop({ required: false, type: Boolean, default: false }) private inline!: boolean;

    @Prop({ default: '', type: [String, Object] }) validate!: string | object;

    @Prop({ required: false, type: [Boolean, null], default: null }) value!: boolean | null;

    @Prop({ required: false, type: String, default: '' })
    private cy!: string;

    dropdownyesNoNullItems = [];

    mounted() {
        this.dropdownyesNoNullItems = this.cloneOf(this.yesNoNullItems);
    }

    /** Liefert eine Kopie von SelectList-Items
     * @devdoc Löst das Problem, dass die RadioList nicht nur den Value zurückliefert sondern auch die Items verändert
     */
    cloneOf(ISelectListItem): Array<ISelectListItem> {
        return cloneDeep(ISelectListItem);
    }

    valueAsString(): string {
        if (this.value !== null) {
            return this.value.toString();
        } else {
            return '';
        }
    }

    /** Liefert die zur Auswahl stehenden Optionen, inklusive einer leeren Auswahl. */
    yesNoNullItems: Array<ISelectListItem> = [
        {
            /* null (as a string value to avoid ambiguity with the actual null value) */
            value: '',
            text: this.labelNull,
            selected: false,
            disabled: false
        },
        {
            /* yes */
            value: 'true',
            text: this.labelYes,
            selected: false,
            disabled: false
        },
        {
            /* no */
            value: 'false',
            text: this.labelNo,
            selected: false,
            disabled: false
        }
    ];

    private onFocusout(value) {
        this.$emit('focusout', value);
    }

    /** Gibt den geänderten Wert als nullable Boolean aus */
    onChange(newValue: string) {
        if (newValue === 'true') {
            this.$emit('change', true);
        }
        if (newValue === 'false') {
            this.$emit('change', false);
        }
        if (newValue === '') {
            this.$emit('input', null);
        }
    }

    /** Gibt den geänderten Wert als nullable Boolean aus */
    onInput(newValue: string) {
        if (newValue === 'true') {
            this.$emit('input', true);
        }
        if (newValue === 'false') {
            this.$emit('input', false);
        }
        if (newValue === '') {
            this.$emit('input', null);
        }
    }
}
