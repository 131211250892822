
import Vue from 'vue';
import { namespace } from 'vuex-class';

import { NAME as NAME_VALIDATION } from '@/store/modules/validation';
const validationModul = namespace(NAME_VALIDATION);

import { Component, Prop, Watch } from 'vue-property-decorator';
import { GET_VALIDATION_KEY } from '@/store/modules/validation/getters';

@Component

/** Implementiert eine Textbox mit Label und kompletter Validierung
 * @remarks Rendert je eine Spalte für das Label und das Eingabefeld (sm-3 und sm-9 im Bootstrap-Grid)
 */
export default class NowhowTextbox extends Vue {
    /** Validierung via Validations-Modul */
    @validationModul.Getter(GET_VALIDATION_KEY) getValidation!: (key: string) => string;

    @Prop({ required: true, type: String }) private id!: string;

    @Prop({ required: false, type: String, default: 'text' }) private inputType!: string;

    @Prop({ required: false, type: String, default: '' }) private label!: string;

    @Prop({ required: false, type: String, default: '' }) private placeholder!: string;

    @Prop({ required: false, type: String, default: '' }) private autocomplete!: string;

    @Prop({ required: false, type: Boolean, default: false }) private disabled!: boolean;

    @Prop({ required: false, type: Boolean, default: false }) private readonly!: boolean;

    /** Wenn festgelegt, verzögert die Validierung um die angegebene Anzahl Millisekunden
     * @devdoc Kann verwendet werden, um Probleme mit Layoutverschiebungen aufgrund von Validierungs-Meldungen bei
     * Mausklicks auf andere Elemente zu entschärfen
     * @devdoc  Siehe https://vee-validate.logaretm.com/v2/guide/components/validation-provider.html#props
     */
    @Prop({ required: false, type: Number, default: 0 }) private validationDelay!: number;

    @Prop({ default: '', type: [String, Object] }) validate!: string | object;

    @Prop({ required: true, default: '' }) private value!: any;

    @Prop({ required: false, type: String, default: '' })
    private cy!: string;

    /* eslint-disable-next-line no-empty, @typescript-eslint/no-empty-function */
    @Prop({ required: false, type: Function, default: function() {} })
    private onEnter!: Function;

    private provider: any;

    mounted() {
        const inputElement: any = document.getElementById(String(this.id));
        inputElement.value = this.value;

        this.provider = this.$refs['provider'];
    }

    public focus() {
        (this.$refs.inputfield as HTMLInputElement).focus();
    }

    private onChange(value) {
        this.$emit('change', value);
    }

    private onFocusout(value) {
        this.$emit('focusout', value);
    }

    private onInput(value) {
        this.$emit('input', value);
    }

    @Watch('value', { deep: true, immediate: true })
    private onValueChanged() {
        this.provider = this.$refs['provider'];
        this.provider?.validate(this.value, { silent: true });
    }
}
