
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component

/** Ein einfaches Overlay mit einer Text-Message und einem OK-Button zum schliessen
 * @param {boolean} value - Legt die Sichtbarkeit fest
 * @param {String} text - Die Text-Message
 */
export default class NowhowMessageOverlay extends Vue {
    /** Text für die Anzeige, falls nicht der Slot verwendet wird
     */
    @Prop({ required: false, type: String, default: '' }) private text!: string;

    /** Sichtbarkeit des Overlays
     */
    @Prop({ required: false, type: Boolean, default: true }) private value!: boolean;

    @Prop({ required: false, type: String, default: 'nowhowMessageOverlay' }) private id!: string;

    close() {
        this.$emit('input', false);
        this.$emit('change', false);
    }
}
