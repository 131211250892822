import { MutationTree } from 'vuex';
import { IFeatureResult, IFeatureSearchResult } from '../api-viewmodel';
import { ISwisstopoState } from './state';

/** Weist ein Resultat einer Feature-Suche zu */
export const MAP_FEATURE_SEARCH_RESULT = 'mapFeatureSearchResult';

/** Weist ein Resultat einer Feature-Abfrage dem selektierten Feature zu */
export const MAP_SELECTED_FEATURE = 'mapSelectedFeature';

export const mutations: MutationTree<ISwisstopoState> = {
    [MAP_FEATURE_SEARCH_RESULT](state, value: IFeatureSearchResult) {
        state.searchResult = value;
    },
    [MAP_SELECTED_FEATURE](state, value: IFeatureResult) {
        state.selectedFeature = value.feature;
    }
};
