import { IRootState } from '@/store';
import { ActionTree } from 'vuex';
import { delay } from 'lodash';
import i18n from '@/plugins/i18n';
import { IEntryState, state } from './state';
import { entryService } from '../services/EntryService';
import { SHOW, HIDE } from '@/store/modules/loadingSpinner/actions';
import { NEW_SUCESS, NEW_ALERT } from '@/store/modules/headerNotification/actions';
import { ACTIVATE, DEACTIVATE } from '@/store/modules/saveIndicator/actions';

import {
    MAPCASEFULLDATA,
    MAPCASEVALIDATIONMESSAGES,
    MAPCASECONTACTDATA,
    MAPCASESTEPINFO,
    WIPECASEDATA
} from './mutations';
import {
    ICaseApprovalDto,
    ICaseContactDataViewModel,
    ICaseStepInfoViewModel,
    IEnterpriseUnitDataObject,
    ILocalUnitDataObject,
    IActivityDataObject
} from '@/api-viewmodel';

/** Die Zeit, die gewartet wird, bevor eine server-Seitige Validierungsanfrage abgesetzt wird.
 * @devdoc Idealerweise die Zeit, die Axios für einen allfällig vorher abgesetzten Update-Request verwendet wird.
 * Hier, aktuell, empirisch festgelegt.
 */
const serverValidationDeferralTimeMilliseconds = 1500;

/** Pfad zu der Loading-Spinner-Komponente */
const LOADING_SPINNER = 'loadingSpinner/';

/** Pfad zu der Save-Indicator-Komponente */
const SAVE_INDICATOR = 'saveIndicator/';

/** Pfad zu der Header-Notification-Komponente */
const HEADER_NOTIFICATION = 'headerNotification/';

/** Bearbeiten einer EnterpriseUnit */
export const SUBMIT_ENTERPRISE_UNIT_DATA = 'SUBMIT_ENTERPRISE_UNIT_DATA';

/** Bearbeiten der Adress-Daten einer LocalUnit
 * @remarks Enthält auch die ActivityDescription und den NOGA Code
 */
export const SUBMIT_LOCAL_UNIT_ADDRESS_DATA = 'SUBMIT_LOCAL_UNIT_ADDRESS_DATA';

/** Bearbeiten der Tätigkeits-Daten einer LocalUnit */
export const SUBMIT_LOCAL_UNIT_ACTIVITY_DATA = 'SUBMIT_LOCAL_UNIT_ACTIVITY_DATA';

/** Bearbeiten der Census-Daten einer LocalUnit */
export const SUBMIT_LOCAL_UNIT_CENSUS_DATA = 'SUBMIT_LOCAL_UNIT_CENSUS_DATA';

/** Holt die aktuellen vollen Daten für den zu bearbeitenden Fall in den State */
export const GET_CASE_FULL_DATA = 'GET_CASE_FULL_DATA';

/** Holt die Validations-Meldungen für den zu bearbeitenden Fall in den State */
export const GET_CASE_VALIDATION_MESSAGES = 'GET_CASE_VALIDATION_MESSAGES';

/** Eingaben bestätigen und den Fall im Entry-Wizard abschliessen
 * @remarks Übermittelt die explizite Zustimmung des Benutzers und die allfällige E-Mail-Adresse für die nächste Befragung.
 */
export const SUBMIT_CASE_APPROVAL = 'SUBMIT_CASE_APPROVAL';

/** Den aktuellen Step auf dem Fall (für die spätere Verwendung als Einstiegspunkt) speichern
 */
export const SUBMIT_CASE_STEPINFO = 'SUBMIT_CASE_STEPINFO';

/** Versendet die bearbeiteten Kontakt-Daten für den zu bearbeitenden Fall an das Backend */
export const SUBMIT_CASE_CONTACT_DATA = 'SUBMIT_CASE_CONTACT_DATA';

/** Entfernt die Case-Daten für den zu bearbeitenden Fall aus dem State
 * @remarks Muss ausgeführt werden, wenn der Eingabe-Wizard verlassen wird, oder aus einem anderen Grund kein Fall oder ein anderer Fall bearbeitet wird
 */
export const REMOVE_CASE_DATA = 'REMOVE_CASE_DATA';

export const actions: ActionTree<IEntryState, IRootState> = {
    /** Holt die vollen Daten zum Fall.
     * @remarks Für einen BFS-Benutzer ist die Verwendung einer CaseId zwingend, da diese grundsätzlich alle Fälle bearbeiten können.
     * Für einen externen Benutzer ist die Verwendung einer CaseId nicht valide, da diese nur den mit ihrem Account verbundenen Fall bearbeiten dürfen.
     * @param CaseId CaseId des zu bearbeitenden Falles,
     * oder leer wenn es sich um einen externen Benutzer handelt. */
    [GET_CASE_FULL_DATA]({ dispatch, commit }: any, caseId: bigint) {
        dispatch(LOADING_SPINNER + SHOW, {}, { root: true });

        console.debug('GET_CASE_FULL_DATA for caseId:=' + caseId);

        //Aktion durchführen und Resultat zuweisen
        entryService
            .getCaseFullData(caseId)
            .then(response => {
                commit(MAPCASEFULLDATA, response.data);
            })
            .then(() => {
                dispatch(LOADING_SPINNER + HIDE, {}, { root: true });
            });
    },

    [GET_CASE_VALIDATION_MESSAGES]({ dispatch, commit }: any, caseId: bigint) {
        dispatch(LOADING_SPINNER + SHOW, {}, { root: true });

        console.debug('GET_CASE_VALIDATION for caseId:=' + caseId);

        //Aktion durchführen und Resultat zuweisen
        //Hier wird ein Delay verwendet, um möglichst sicherzustellen, dass vorangegangene Update-Requests (e.g. Census-Updates) schon vom Server verarbeitet wurden
        //Ansonsten führt der Server die Validierungsfunktion mit veralteten Daten aus
        delay(function() {
            entryService
                .getCaseValidationMessages(caseId)
                .then(response => {
                    commit(MAPCASEVALIDATIONMESSAGES, response.data);
                })
                .then(() => {
                    dispatch(LOADING_SPINNER + HIDE, {}, { root: true });
                });
        }, serverValidationDeferralTimeMilliseconds);
    },
    [SUBMIT_CASE_CONTACT_DATA]({ dispatch, commit }: any, params: { caseId: bigint; data: ICaseContactDataViewModel }) {
        //Für diese Zwischenspeicherung während der Dateneingabe wird auf den Spinner verzichtet, um die sofortige Weiterarbeit im Wizard zu ermöglichen
        dispatch(SAVE_INDICATOR + ACTIVATE, {}, { root: true });

        //Aktion als Roundtrip mit anschliessender Datenübernahme durchführen
        entryService
            .submitCaseContactData(params)
            .then(response => {
                commit(MAPCASECONTACTDATA, response.data);
            })
            .then(() => {
                dispatch(SAVE_INDICATOR + DEACTIVATE, {}, { root: true });
            });
    },
    [SUBMIT_CASE_APPROVAL](
        { dispatch, commit }: any,
        params: { caseId: bigint; data: ICaseApprovalDto; onSuccess: Function }
    ) {
        dispatch(LOADING_SPINNER + SHOW, {}, { root: true });

        //Aktion als Roundtrip mit anschliessender Daten-Entfernung durchführen
        entryService
            .submitCaseApprovalData(params)
            .then(response => {
                //Nur wenn der Fall erfolgreich abgeschlossen wurde, Daten entfernen und Logout
                if (response.status === 200) {
                    commit(WIPECASEDATA);
                    dispatch(
                        HEADER_NOTIFICATION + NEW_SUCESS,
                        { text: i18n.t('allg_transfer_success') },
                        { root: true }
                    );
                    params.onSuccess();
                }
                //Ansonsten, ausser der angezeigten Fehlermeldung, nichts tun um die weitere Bearbeitung des Falles zu ermöglichen
            })
            .then(() => {
                dispatch(LOADING_SPINNER + HIDE, {}, { root: true });
            });
    },

    [SUBMIT_CASE_STEPINFO]({ dispatch, commit }: any, params: { caseId: bigint; data: ICaseStepInfoViewModel }) {
        //Für diese Zwischenspeicherung während der Dateneingabe wird auf den Spinner verzichtet, um die sofortige Weiterarbeit im Wizard zu ermöglichen
        dispatch(SAVE_INDICATOR + ACTIVATE, {}, { root: true });
        entryService
            .submitStepInfo(params)
            .then(response => {
                commit(MAPCASESTEPINFO, response.data);
            })
            .then(() => {
                dispatch(SAVE_INDICATOR + DEACTIVATE, {}, { root: true });
            });
    },

    [SUBMIT_ENTERPRISE_UNIT_DATA](
        { dispatch, commit }: any,
        params: { caseId: bigint; data: IEnterpriseUnitDataObject }
    ) {
        //Für diese Zwischenspeicherung während der Dateneingabe wird auf den Spinner verzichtet, um die sofortige Weiterarbeit im Wizard zu ermöglichen
        dispatch(SAVE_INDICATOR + ACTIVATE, {}, { root: true });
        entryService
            .submitEnterpriseUnitData(params)
            .then(response => {
                commit(MAPCASEFULLDATA, response.data);
            })
            .then(() => {
                dispatch(SAVE_INDICATOR + DEACTIVATE, {}, { root: true });
            });
    },
    [SUBMIT_LOCAL_UNIT_ACTIVITY_DATA](
        { dispatch, commit }: any,
        params: { caseId: bigint; data: IActivityDataObject }
    ) {
        //Für diese Zwischenspeicherung während der Dateneingabe wird auf den Spinner verzichtet, um die sofortige Weiterarbeit im Wizard zu ermöglichen
        dispatch(SAVE_INDICATOR + ACTIVATE, {}, { root: true });

        entryService
            .submitLocalUnitActivityData(params)
            .then(response => {
                commit(MAPCASEFULLDATA, response.data);
            })
            .then(() => {
                dispatch(SAVE_INDICATOR + DEACTIVATE, {}, { root: true });
            });
    },
    [SUBMIT_LOCAL_UNIT_ADDRESS_DATA](
        { dispatch, commit }: any,
        params: { caseId: bigint; data: ILocalUnitDataObject }
    ) {
        //Für diese Zwischenspeicherung während der Dateneingabe wird auf den Spinner verzichtet, um die sofortige Weiterarbeit im Wizard zu ermöglichen
        dispatch(SAVE_INDICATOR + ACTIVATE, {}, { root: true });

        entryService
            .submitLocalUnitAddressData(params)
            .then(response => {
                commit(MAPCASEFULLDATA, response.data);
            })
            .then(() => {
                dispatch(SAVE_INDICATOR + DEACTIVATE, {}, { root: true });
            });
    },

    [SUBMIT_LOCAL_UNIT_CENSUS_DATA]({ dispatch, commit }: any, params: { caseId: bigint; data: ILocalUnitDataObject }) {
        //Für diese Zwischenspeicherung während der Dateneingabe wird auf den Spinner verzichtet, um die sofortige Weiterarbeit im Wizard zu ermöglichen
        dispatch(SAVE_INDICATOR + ACTIVATE, {}, { root: true });

        entryService
            .submitLocalUnitCensusData(params)
            .then(response => {
                commit(MAPCASEFULLDATA, response.data);
            })
            .then(() => {
                dispatch(SAVE_INDICATOR + DEACTIVATE, {}, { root: true });
            });
    },
    [REMOVE_CASE_DATA]({ dispatch, commit }: any) {
        commit(WIPECASEDATA);
    }
};
