
import Vue from 'vue';
import { ValidationObserver } from 'vee-validate';
import { namespace } from 'vuex-class';
import { Component } from 'vue-property-decorator';
import { ENTRY_WIZARD_ACTIVITY, ENTRY_WIZARD_CONTACT, ENTRY_WIZARD_LOCAL } from '@/router';
import { EnterpriseStepIndex } from '../views/Wizard.vue';
import StepHeader from './StepHeader.vue';

import { NAME } from '../store/index';
const modul = namespace(NAME);

//Getters und Actions
import { CASE_FULL_DATA, CASE_ENTERPRISE_UNIT } from '../store/getters';
import EnterpriseUnitEdit from './EnterpriseUnitEdit.vue';

//Footer-Bar und -Buttons
import { NAME as NAME_FOOTERBAR } from '@/store/modules/footerBar';
const footerBarModul = namespace(NAME_FOOTERBAR);
import { NEW_BUTTON, CLOSE } from '@/store/modules/footerBar/actions';
import { FooterButtonType, IFooterButton } from '@/store/modules/footerBar/state';
import FooterBarRightButtonProxy from '@/components/FooterBarRightButtonProxy.vue';
import { SUBMIT_CASE_STEPINFO } from '../store/actions';
import { ICaseFullDataViewModel, CaseStepInfoViewModel, IEnterpriseUnitDataObject } from '@/api-viewmodel';
@Component({
    components: { StepHeader, EnterpriseUnitEdit, FooterBarRightButtonProxy }
})
export default class StepEnterprise extends Vue {
    //Getters
    @modul.Getter(CASE_FULL_DATA) caseFullData!: ICaseFullDataViewModel;
    @modul.Getter(CASE_ENTERPRISE_UNIT) enterpriseUnit!: IEnterpriseUnitDataObject;

    //Actions
    @modul.Action(SUBMIT_CASE_STEPINFO) submitStepInfo!: Function;
    @footerBarModul.Action(NEW_BUTTON) newFooterBar!: Function;
    @footerBarModul.Action(CLOSE) closeFooterBar!: Function;

    mounted() {
        console.log('StepEnterprise.vue::mounted');

        //Den aktuellen Step sofort melden
        this.submitStepInfo({
            caseId: this.caseFullData.caseId,
            data: new CaseStepInfoViewModel(EnterpriseStepIndex, null)
        });

        this.showStepNavigation(false);
    }

    /** Definiert die Wizard-Navigations-Buttons und zeigt diese an */
    showStepNavigation(disabled: boolean) {
        console.debug('StepEnterprise.vue::showStepNavigation');

        const buttonLeft: Array<IFooterButton> = [
            {
                onClick: () => {
                    this.gotoPreviousStep();
                },
                label: 'btn_wzrd_back',
                type: FooterButtonType.secondary,
                disabled: disabled
            }
        ];
        const buttonRight: Array<IFooterButton> = [
            {
                onClick: () => {
                    this.gotoNextStep();
                },
                label: 'btn_wzrd_next',
                type: FooterButtonType.primary,
                disabled: disabled
            }
        ];
        this.closeFooterBar();
        this.newFooterBar({ buttonLeft, buttonRight });
    }

    /** Navigiert zum vorangehenden Step im Entry-Wizard. */
    gotoPreviousStep() {
        this.$router.push({ name: ENTRY_WIZARD_LOCAL });
    }

    /** Navigiert zum nächsten Step im Entry-Wizard. */
    gotoNextStep() {
        this.$router.push({ name: ENTRY_WIZARD_ACTIVITY });
    }
}
