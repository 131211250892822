
//Imports für dieses Modul
import Vue from "vue";
import { namespace } from "vuex-class";
import { NAME } from "../store/index";
import { Component } from "vue-property-decorator";
import NowhowTextbox from "@/components/NowhowTextbox.vue";
import Step1AccessCode from "../components/Step1AccessCode.vue";
import Step2Password from "../components/Step2Password.vue";
import Step3Result from "../components/Step3Result.vue";
import { IS_SURVEY_NAME_ASSIGNED, REGISTRATION_RESULT } from "../store/getters";

const modul = namespace(NAME);

@Component({
    components: {
        Step1AccessCode,
        Step2Password,
        Step3Result,
        NowhowTextbox,
    },
})

/** Registrierung an der Anwendung.
 * @remarks Implementiert {@link https://collaboration.nowhow.ch/erst/foswiki/bin/view/Erst/RegistrierungScreen}
 * und die darauf folgenden Screens im Wizard-Style.
 * @remarks Die Eingabe des Zugriffscodes kann auch via URL im Stile von {@link http://localhost:8080?AccessCode=TESTACCESSCD} erfolgen
 * @remarks Implementiert einen Teil der Anforderungen REGI-601, REGI-602, REGI-603, REGI-604
 * @devdoc
 * Diese Komponente enthält 3 Teile, welche die Wizard-Steps abbilden
 *
 * Teil 1: Die Eingabe und Prüfung des Zugangscodes. Im Erfolgsfall wird der Name der zugehörigen Befragung im Store abgelegt (Anzeige, wenn keine weiteren Schritte erfolgt sind)
 * Teil 2: Eingabe von E-Mail und dem gewählten Passwort. Hier kann die eigentliche Registration ausgelöst werden. Das Resultat der Registration (Erfolgs- oder Fehlerfall) wird im Store abgelegt (Anzeige wenn ein ein Survey-Name verfügbar ist)$
 * Teil 3: Anzeige des Resultat als Erfolg oder Fehler. (Anzeige gemäss Resultat)
 */
export default class AccountRegister extends Vue {
    @modul.Getter(IS_SURVEY_NAME_ASSIGNED) isSurveyNameAssigned!: boolean;
    @modul.Getter(REGISTRATION_RESULT) getRegistrationResult!: boolean;

    //Hinweis: Der AccessCode wird lokal im Zustand geführt, um ihn für die Registrierung im Schritt 2 verfügbar zu haben
    accessCode = "";

    /** Beim Verlassen der Registrierung den Zustand zurücksetzen.
     * @remarks Ermöglich den erneuten Start der Registrierung durch erneute Navigation von/zu der Registrierung
     */
    beforeDestroy(): void {
        //Access Code und Registartion löschen, um den späteren Neubeginn auf dem ersten Schritt zu erzwingen.
        this.accessCode = "";
    }

    /** Weist einen in Schritt 1 geänderten AccessCode dem Zustand dieser Komponente zu */
    onAccesCodeInput(accessCode): void {
        this.accessCode = accessCode;
    }

    /** Gibt an, ob die Registration erfolgreich war */
    get isRegistrationSuccessful(): boolean {
        return this.getRegistrationResult === true;
    }

    /** Gibt an, ob die Registration fehlschlug */
    get isRegistrationFailed(): boolean {
        return this.getRegistrationResult === false;
    }

    /** Gibt an, ob die Registration abgeschlossen ist */
    get isRegistrationDone(): boolean {
        return this.getRegistrationResult !== null;
    }
}
