export const I18N_LANGUAGE = 'i18nLanguage';

export interface IResourceState {
    language: string;
    loadedLanguages: Array<string>;
}

let language: string;

const browserLanguage = navigator.language.slice(0, 2);
const browserLanguageSupported = process.env.VUE_APP_I18N_SUPPORTED_LANGUAGE.split(',').includes(browserLanguage);
if (localStorage.getItem(I18N_LANGUAGE)) {
    language = localStorage.getItem(I18N_LANGUAGE) as string;
} else if (browserLanguageSupported) {
    language = browserLanguage;
} else {
    language = process.env.VUE_APP_I18N_FALLBACK_LANGUAGE;
}
localStorage.setItem(I18N_LANGUAGE, language);

export const state: IResourceState = {
    language: language,
    loadedLanguages: []
};
