
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { IMaskComponent, IMask } from "vue-imask";

@Component({
    components: {
        "imask-input": IMaskComponent,
    },
})

/** Implementiert eine Dreifach-Textbox mit Label für die Eingabe eines Access-Codes. Die Komponenten behandelt eigenständig die Fokussierung und die Textlänge
 * innerhalb der 3 Eingabefelder. Es findet jedoch keine Validierung der Eingabe selbst statt.
 * @remarks Rendert je eine Spalte für das Label und die 3 Eingabefelder (sm-3 und sm-9 im Bootstrap-Grid)
 */
export default class AccessCodeTextbox extends Vue {
    @Prop({ required: true, type: String }) private id!: string;

    @Prop({ required: false, type: String, default: "" })
    private label!: string;

    @Prop({ required: false, type: Boolean, default: false })
    private required!: boolean;

    /** Der initial anzuzeigende AccessCode (12 Zeichen ohne Trennzeichen) */
    @Prop({ required: false, default: "" }) private initialAccessCode!: string;

    private patternMask = IMask.createMask({
        mask: "#### - #### - ####",
        definitions: {
            "#": /[ABCDEFGHJKLMNPQRSTUVWXYZ23456789]/,
        },
        unmask: true,
        prepare: function(str) {
            return str.toUpperCase();
        },
    });

    /** Meldet die Änderung des Access-Code an die übergeordnete Komponente.
     */
    public onChange(value: string): void {
        console.debug("onAccept:unmaskedValue:", value);
        this.$emit("input", value);
    }
}
