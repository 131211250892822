
import Vue from 'vue';
import { defaultTo } from 'lodash';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { GET_VALIDATION_KEY } from '@/store/modules/validation/getters';

import NowhowTextbox from '@/components/NowhowTextbox.vue';
import NowhowTextline from '@/components/NowhowTextline.vue';
import NowhowRadioList from '@/components/NowhowRadioList.vue';
import NowhowRadioYesNo from '@/components/NowhowRadioYesNo.vue';
import NowhowTextarea from '@/components/NowhowTextarea.vue';
import NowhowUInt32Box from '@/components/NowhowUInt32Box.vue';
import NowhowRequiredInfoline from '@/components/NowhowRequiredInfoline.vue';
import SwisstopoFeatureSearch from '@/areas/swisstopo/components/SwisstopoFeatureSearch.vue';

import { NAME } from '../store/index';
const modul = namespace(NAME);

import { NAME as NAME_VALIDATION } from '@/store/modules/validation';
const validationModul = namespace(NAME_VALIDATION);

import { SUBMIT_ENTERPRISE_UNIT_DATA } from '../store/actions';
import { CASE_ID } from '../store/getters';

import { IEnterpriseUnitDataObject } from '@/api-viewmodel';
import { IFeature } from '@/areas/swisstopo/api-viewmodel';

@Component({
    components: {
        NowhowTextline,
        NowhowRequiredInfoline,
        NowhowRadioList,
        NowhowRadioYesNo,
        NowhowTextbox,
        NowhowTextarea,
        NowhowUInt32Box,
        SwisstopoFeatureSearch
    }
})

/** Bereich für die Bearbeitung der Details eines Unternehmens */
export default class EnterpriseUnitEdit extends Vue {
    //Getters
    @modul.Getter(CASE_ID) caseId!: bigint;

    @validationModul.Getter(GET_VALIDATION_KEY) getValidation!: (key: string) => string;

    //Actions
    @modul.Action(SUBMIT_ENTERPRISE_UNIT_DATA) submitEnterpriseUnitData!: Function;

    @Prop({ required: true, type: Object })
    private enterpriseUnit!: IEnterpriseUnitDataObject;

    mounted() {
        //Aktuelles Token der unveränderte Datenn für die spätere, erstmalige Prüfung merken
        this.lastSubmittedDataToken = this.submittableDataToken;
    }

    /** Behandelt die Auswahl eines Features aus der Swisstopo-Komponente, indem die Adressdaten in die
     * entsprechenden Eingabefelder befüllt werden
     * @remarks Implementiert das Mapping gemäss https://collaboration.nowhow.ch/erst/foswiki/bin/view/Erst/MappingSberNachErst#EnterpriseUnit
     */
    private onEnterpriseSwisstopoFeatureSelected(feature: IFeature): void {
        console.debug('EnterpriseUnitEdit::onEnterpriseSwisstopoFeatureSelected', feature);
        this.enterpriseUnit.street = feature.attributes.strname_deinr.replace(feature.attributes.deinr, '').trim();
        this.enterpriseUnit.houseNumber = feature.attributes.deinr;
        this.enterpriseUnit.zipCode = feature.attributes.dplz4;
        this.enterpriseUnit.town = feature.attributes.dplzname;
        this.enterpriseUnit.municipality = feature.attributes.ggdename;
        this.enterpriseUnit.canton = feature.attributes.gdekt;
        this.enterpriseUnit.egId = defaultTo(Number.parseInt(feature.attributes.egid), null);
        this.enterpriseUnit.edId = defaultTo(Number.parseInt(feature.attributes.edid), null);
        this.enterpriseUnit.esId = feature.attributes.esid;
        this.enterpriseUnit.gdeId = feature.attributes.ggdenr;
        this.enterpriseUnit.country = 'CH'; //Default-Wert für eine Swisstopo-Adresse
    }

    /** Behandelt den Fall, dass die Swisstopo-Schnittstelle nicht verfügbar ist
     */
    private onEnterpriseSwisstopoUnavailable() {
        this.isEnterpriseSwisstopoUnavailable = true;
        //Manuelle Eingabe einschalten
        this.enterpriseUnit.isSwisstopoDismissed = true;
    }

    /** Behandelt das Auswählen des "Dismissed"-Zustandes für die Swisstopo-Adresssuche
     * @remarks Steuert die manuelle Eingabemöglichkeit für die Adressfelder
     * @devdoc Die Controls müssen mit :readonly (nicht :disabled) gesteuert werden, damit die Eingabewerte
     * trotzdem weiterverarbeitet werden
     */
    private onEnterpriseSwisstopoDismissedChange() {
        this.enterpriseUnit.isSwisstopoDismissed = !this.enterpriseUnit.isSwisstopoDismissed;
    }

    beforeDestroy() {
        this.saveStepData();
    }

    /** Lokales Flag, welches einen allfälligen Fehlerzustand der Swisstopo-Schnittstelle anzeigt
     * @remarks Implementiert die Anforderung zum "Fallback" für die Adresseingabe
     */
    isEnterpriseSwisstopoUnavailable = false;

    /** Behandelt die manuelle Änderung der Adressdaten
     * @remarks Werden die Adressdaten manuell geändert, sind die Swisstopo-spezifischen Id's nicht mehr gültig.
     * Implementiert die Anforderung gemäss DatenErfassungUnternehmenScreen-105
     * Siehe auch MapServerSwisstopoFunktion
     */
    private onManualEnterpriseAddressInput() {
        this.enterpriseUnit.municipality = null;
        this.enterpriseUnit.canton = null;
        this.enterpriseUnit.egId = null;
        this.enterpriseUnit.edId = null;
        this.enterpriseUnit.esId = null;
        this.enterpriseUnit.gdeId = null;
        this.enterpriseUnit.country = 'CH'; //Default-Wert für eine manuell eingegebene Adresse, es werden nur Schweizer Adressen unterstützt;
    }

    /** Speichert die aktuell eingegebenen Werte auf das Backend
     * @remarks Die Daten werden nur versendet, wenn diese seit dem letzten Versand oder erstmalig geändert haben
     * Für diese Prüfung werden alle Eingabewerte dieser Komponente verwendet
     * @devdoc Diese Funktion soll beim Verlassen des Bearbeitungsformulars aufgerufen werden
     * Hinweis: Es kann aktuell nicht die Funktionalität der ValidationObserver-Komponente verwendet werden,
     * weil diese keinen zwischenzeitlichen Reset der "Dirty-Flags" erlaubt.
     * Hinweis2: Es wird nicht ein "dirty-tracking" via "changed"-Event implementiert, um nur zum Zeitpunkt des
     * Versandes effektiv veränderte Daten zu versenden.
     */
    saveStepData() {
        console.debug('EnterpriseUnitEdit::saveStepData');

        const submittableDataToken = this.submittableDataToken;
        if (submittableDataToken !== this.lastSubmittedDataToken) {
            this.submitEnterpriseUnitData({
                caseId: this.caseId,
                data: this.enterpriseUnit
            });
            this.lastSubmittedDataToken = submittableDataToken;
        }
    }

    /** Liefert die Validierungsregel für die gemeinesame Eingabe der Postoffice-Box-Angaben
     * @remarks Es müssen entweder alle oder keine PO-Box-Angaben gemacht werden
     * @remarks Implementiert die Anforderung D aus CR1010
     */
    get getPoBoxTogetherRule() {
        if (
            this.enterpriseUnit.postOfficeBoxNumber ||
            this.enterpriseUnit.postOfficeBoxZipCode ||
            this.enterpriseUnit.postOfficeBoxTown
        ) {
            return 'required_labeled:allg_validate_pobox_not_all_set_together';
        }
        //Keine Einschränkung
        return '';
    }

    /** Zwischenspeicher für die Prüfung, ob ein erneuter Versand der Daten notwendig ist */
    lastSubmittedDataToken: string = null;

    /** Liefert ein Token für die versendbaren Daten zur späteren Prüfung, ob ein erneuter Versand notwendig ist.
     * @devdoc Für den effektiven Versand an die API wird das Objekt aus dem Zustand verwendet,
     *  nicht dieses interne DTO.
     */
    get submittableDataToken() {
        return JSON.stringify([
            this.enterpriseUnit.legalUnitName,
            this.enterpriseUnit.nameBusiness,
            this.enterpriseUnit.careOf,
            this.enterpriseUnit.street,
            this.enterpriseUnit.houseNumber,
            this.enterpriseUnit.additionalTxt,
            this.enterpriseUnit.zipCode,
            this.enterpriseUnit.town,
            this.enterpriseUnit.isSwisstopoDismissed,
            this.enterpriseUnit.postOfficeBoxNumber,
            this.enterpriseUnit.postOfficeBoxZipCode,
            this.enterpriseUnit.postOfficeBoxTown,
            this.enterpriseUnit.commentText,

            this.enterpriseUnit.municipality,
            this.enterpriseUnit.canton,
            this.enterpriseUnit.egId,
            this.enterpriseUnit.edId,
            this.enterpriseUnit.esId,
            this.enterpriseUnit.gdeId,
            this.enterpriseUnit.country
        ]);
    }
}
