
import Vue from 'vue';

import { Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class NowhowCheckbox extends Vue {
    @Prop({ required: true, type: String }) private id!: string;

    /** Das Label für die Anzeige mit der Checkbox */
    @Prop({ required: false, type: String, default: '' }) private label!: string;

    /** Das Label für die Anzeige als Formular-Label */
    @Prop({ required: false, type: String, default: '' }) private formLabel!: string;

    @Prop({ required: false, type: Boolean, default: false }) private disabled!: boolean;

    @Prop({ required: false, type: Boolean, default: false }) private readonly!: boolean;

    @Prop({ default: '', type: [String, Object] }) validate!: string | object;

    @Prop({ required: true, type: Boolean, default: false }) private value!: boolean;

    @Prop({ required: false, type: String, default: '' }) private cy!: string;

    private provider: any;
    private touched = false;
    private isSelected = false;

    mounted() {
        this.isSelected = this.value;

        this.provider = this.$refs['provider'];
    }

    public focus() {
        (this.$refs.checkbox as HTMLInputElement).focus();
    }

    private onChange(value) {
        this.provider.validate(value).then(() => {
            this.isSelected = !this.isSelected;

            this.$emit('input', this.isSelected);
            this.$emit('change', this.isSelected);
            this.touched = true;
        });
    }

    private onFocusout(value) {
        this.provider.validate(this.value).then(() => {
            this.$emit('focusout', this.value);
        });
    }

    @Watch('value')
    valueChanged(newVal) {
        this.isSelected = newVal;
    }
}
