
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import { ACCOUNT_LOGIN, ACCOUNT_CONTACT } from '@/router';
@Component
export default class Navigation extends Vue {
    private ACCOUNT_LOGIN = ACCOUNT_LOGIN;
    private ACCOUNT_CONTACT = ACCOUNT_CONTACT;
}
