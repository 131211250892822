
import { cloneDeep } from 'lodash';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import { namespace } from 'vuex-class';

//Footer-Bar und -Buttons
import { NAME as NAME_FOOTERBAR } from '@/store/modules/footerBar';
const footerBarModul = namespace(NAME_FOOTERBAR);
import { NEW_BUTTON, CLOSE } from '@/store/modules/footerBar/actions';
import { FooterButtonType, IFooterButton } from '@/store/modules/footerBar/state';

import NowhowTextbox from '@/components/NowhowTextbox.vue';
import NowhowDismissableTextbox from '@/components/NowhowDismissableTextbox.vue';
import NowhowDatebox from '@/components/NowhowDatebox.vue';
import NowhowTextarea from '@/components/NowhowTextarea.vue';
import NowhowDropdown from '@/components/NowhowDropdown.vue';
import NowhowCheckbox from '@/components/NowhowCheckbox.vue';
import NowhowCheckboxList from '@/components/NowhowCheckboxList.vue';
import NowhowRadioList from '@/components/NowhowRadioList.vue';
import NowhowRadioYesNo from '@/components/NowhowRadioYesNo.vue';
import NowhowMessageOverlay from '@/components/NowhowMessageOverlay.vue';
import NowhowModal from '@/components/NowhowModal.vue';
import SwisstopoFeatureSearch from '@/areas/swisstopo/components/SwisstopoFeatureSearch.vue';
import { ISelectListItem } from '@/types/clienttypes';
import { IS_BAR_VISIBLE } from '@/store/modules/footerBar/getters';
import { IFeature } from '@/areas/swisstopo/api-viewmodel';

@Component({
    components: {
        NowhowTextbox,
        NowhowDismissableTextbox,
        NowhowDatebox,
        NowhowTextarea,
        NowhowDropdown,
        NowhowCheckbox,
        NowhowCheckboxList,
        NowhowRadioList,
        NowhowRadioYesNo,
        NowhowMessageOverlay,
        NowhowModal,
        SwisstopoFeatureSearch
    }
})
export default class Development extends Vue {
    overlayVisible = false;
    modalVisible = false;
    textarea = '';
    textbox = 'Schon mal was gesetzt';
    textbox2 = '';
    datebox = '1.1.2000';
    dropdownValue: Array<string> = ['audi'];
    dismissableTextbox = 'Dismissable text';
    swissTopoSelectedFeature: IFeature = null;

    dropdownItems: Array<ISelectListItem> = [
        {
            value: 'audi',
            text: 'Audi',
            selected: false,
            disabled: false
        },
        {
            value: 'bmw',
            text: 'BMW',
            selected: false,
            disabled: false
        },
        {
            value: 'mercedes',
            text: 'Mercedes',
            selected: true,
            disabled: false
        }
    ];
    checkboxFlag = false;

    checkboxItems: Array<ISelectListItem> = [
        {
            value: 'red',
            text: 'Red',
            selected: false,
            disabled: false
        },
        {
            value: 'green',
            text: 'Green',
            selected: false,
            disabled: false
        },
        {
            value: 'blue',
            text: 'Blue',
            selected: false,
            disabled: false
        }
    ];
    checkboxItemsValue: Array<string> = [];

    radioItems: Array<ISelectListItem> = [
        {
            value: 'male',
            text: 'Male',
            selected: false,
            disabled: false
        },
        {
            value: 'female',
            text: 'Female',
            selected: false,
            disabled: false
        }
    ];
    radioValue = '';

    searchResultUniversityDegree = null;
    searchResultUniversityDegree2 = null;
    searchResultUniversityDegree3 = null;
    radiolistValuePreselected = null;
    dropdownSearchResultItems: Array<ISelectListItem> = [
        {
            value: 1,
            text: 'leicht gefunden',
            selected: false,
            disabled: false
        },
        {
            value: 2,
            text: 'schwer gefunden',
            selected: false,
            disabled: false
        },
        {
            value: 3,
            text: 'nicht gefunden',
            selected: false,
            disabled: false
        },
        {
            value: 4,
            text: 'nicht gesucht',
            selected: false,
            disabled: false
        }
    ];

    dropdownSearchResultItemsCopy = cloneDeep(this.dropdownSearchResultItems);
    radiolistSearchItemsPreselected = cloneDeep(this.dropdownSearchResultItems);

    checkboxFlagValidation = false;

    radioYesNoValue: boolean | null = null;
    radioYesNoItems = [
        {
            value: 'true',
            text: 'YES!',
            selected: false,
            disabled: false
        },
        {
            value: 'false',
            text: 'NOOO!',
            selected: false,
            disabled: false
        }
    ];

    checkboxSyncValue = null;
    checkboxSyncItems = [
        {
            value: 'Montag',
            text: 'Montag',
            selected: false,
            disabled: false
        },
        {
            value: 'Dienstag',
            text: 'Dienstag',
            selected: false,
            disabled: false
        },
        {
            value: 'Mittwoch',
            text: 'Mittwoch',
            selected: false,
            disabled: false
        },
        {
            value: 'Donnerstag',
            text: 'Donnerstag',
            selected: false,
            disabled: false
        },
        {
            value: 'Freitag',
            text: 'Freitag',
            selected: false,
            disabled: false
        }
    ];
    radiolistValuePlanetItems: Array<ISelectListItem> = [
        {
            value: 1,
            text: 'Merkur',
            selected: false,
            disabled: false
        },
        {
            value: 2,
            text: 'Venus',
            selected: false,
            disabled: false
        },
        {
            value: 3,
            text: 'Erde',
            selected: false,
            disabled: false
        },
        {
            value: 4,
            text: 'Mars',
            selected: false,
            disabled: false
        },
        {
            value: 5,
            text: 'Jupiter',
            selected: false,
            disabled: false
        },
        {
            value: 6,
            text: 'Saturn',
            selected: false,
            disabled: false
        },
        {
            value: 7,
            text: 'Uranus',
            selected: false,
            disabled: false
        },
        {
            value: 8,
            text: 'Neptun',
            selected: false,
            disabled: false
        },
        {
            value: null,
            text: '(keiner)',
            selected: false,
            disabled: false
        }
    ];
    radiolistValuePlanet: number = null;

    @footerBarModul.Action(CLOSE) closeFooterBar!: Function;
    @footerBarModul.Action(NEW_BUTTON) newFooterBar!: Function;
    @footerBarModul.Getter(IS_BAR_VISIBLE) footerBarVisible!: boolean;

    setPreselectedNull() {
        this.radiolistValuePreselected = null;
    }

    setPreselected3() {
        this.radiolistValuePreselected = 3;
    }

    toggleFooter() {
        if (this.footerBarVisible) {
            this.closeFooterBar();
        } else {
            const buttonLeft: Array<IFooterButton> = [
                {
                    onClick: () => {
                        alert('button1 clicked');
                    },
                    label: 'button1',
                    type: FooterButtonType.primary
                }
            ];
            const buttonCenter: Array<IFooterButton> = [
                {
                    onClick: (button: IFooterButton) => {
                        this.logButtonClick(button);
                    },
                    label: 'buttonCenter primary 1',
                    type: FooterButtonType.primary
                },
                {
                    onClick: (button: IFooterButton) => {
                        this.logButtonClick(button);
                    },
                    label: 'buttonCenter secondary',
                    type: FooterButtonType.secondary
                }
            ];
            const buttonRight: Array<IFooterButton> = [
                {
                    onClick: (button: IFooterButton) => {
                        this.logButtonClick(button);
                    },
                    label: 'button disabled',
                    type: FooterButtonType.primary,
                    disabled: true
                }
            ];

            this.newFooterBar({ buttonLeft, buttonCenter, buttonRight });
        }
    }

    showOverlay() {
        this.overlayVisible = true;
    }

    logButtonClick(button: IFooterButton) {
        alert('button:=' + button.label + ' clicked, alert from function in vue-component');
    }

    onSwisstopoFeatureSelected(feature: IFeature) {
        console.debug('Development::onSwisstopoFeatureSelected', feature);
        this.swissTopoSelectedFeature = feature;
    }
    mounted() {
        // select first item in radiolist
        // this.radiolistSearchItemsPreselected[0].selected = true;
        this.radiolistValuePreselected = 2;
        // set value as String instead of Number
        //this.radiolistValuePreselected = null;
    }
}
