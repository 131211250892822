
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import { namespace } from 'vuex-class';

import { NAME as NAME_RESOURCE } from '@/store/modules/resource';
const resourceModul = namespace(NAME_RESOURCE);
import { SET_LANGUAGE } from '@/store/modules/resource/actions';
import { GET_LANGUAGE } from '@/store/modules/resource/getters';

import { NAME as NAME_ACCOUNT } from '@/areas/account/store';
const accountModul = namespace(NAME_ACCOUNT);

import { NAME as NAME_ENTRY } from '@/areas/entry/store';
const entryModul = namespace(NAME_ENTRY);

import { NAME as NAME_ENVIRONMENT } from '@/store/modules/environment';
const environmentModul = namespace(NAME_ENVIRONMENT);

import { VUE_APP_ENVIRONMENT } from '@/store/modules/environment/getters';
import { CASE_ID } from '@/areas/entry/store/getters';
import { IS_LOGGED_IN } from '@/areas/account/store/getters';
import { LOGOUT } from '@/areas/account/store/actions';
import { ACCOUNT_LOGIN, ACCOUNT_REGISTER, ACCOUNT_CONTACT, HOME, ENTRY_WIZARD_INFO } from '@/router';

@Component({
    components: {}
})
export default class Header extends Vue {
    private ACCOUNT_LOGIN = ACCOUNT_LOGIN;
    private ACCOUNT_REGISTER = ACCOUNT_REGISTER;
    private ACCOUNT_CONTACT = ACCOUNT_CONTACT;
    private ENTRY_WIZARD_INFO = ENTRY_WIZARD_INFO;
    private HOME = HOME;

    @resourceModul.Action(SET_LANGUAGE) setLanguage: Function;
    @accountModul.Action(LOGOUT) logout!: Function;
    @resourceModul.Getter(GET_LANGUAGE) language!: string;
    @accountModul.Getter(IS_LOGGED_IN) loggedIn!: boolean;
    @entryModul.Getter(CASE_ID) caseId!: bigint;
    @environmentModul.Getter(VUE_APP_ENVIRONMENT) vueAppEnvironment!: string;

    /** Führt ein Logout an der Anwendung aus
     * @remarks Für allfällig bearbeitete Daten im Wizard wird vor dem Logout noch
     * ein Speichervorgang ausgelöst.
     */
    private handleLogout() {
        //Hinweis: Die "logout" Route existiert nicht und ergibt eine Warnung in der Konsole
        //Die Route wird hier vor dem effentiven Logout aufgerufen, um noch vorher
        //das Speichern (via "beforeDestroy") im Wizard auszulösen.
        //Nach dem effektiven Logout mit dem clientseitigen
        //Entfernen der Falldaten, wäre das Speichern im Wizard nicht mehr möglich.
        this.$router.push({ name: 'logout' }).then(() => {
            this.logout().then(() => {
                this.$router.push({ name: ACCOUNT_LOGIN });
                console.info('The user has logged out');
            });
        });
    }

    private navigate() {
        console.debug('Header.vue::navigate');
        this.$router.push({ name: ENTRY_WIZARD_INFO });
    }

    private get DeCss() {
        return this.language === 'de' ? 'active' : '';
    }
    private get FrCss() {
        return this.language === 'fr' ? 'active' : '';
    }
    private get ItCss() {
        return this.language === 'it' ? 'active' : '';
    }

    /** Gibt an, ob die Service-Navigation (inkl. Sprachwahl) benötigt wird
     * @remarks Die Service-Navigation (inkl. Sprachwahl) wird grundsätzlich immer dargestellt.
     * Via optionales Property "requiresServiceNavigation" in den Metadaten der Route kann das
     * jedoch explizit übersteuert werden.
     */
    isServiceNavigationRequired(): boolean {
        //Explizit definiert?
        if (
            this.$route.meta?.requiresServiceNavigation === true ||
            this.$route.meta?.requiresServiceNavigation === false
        ) {
            console.debug('requiresServiceNavigation', this.$route.meta?.requiresServiceNavigation);
            return this.$route.meta?.requiresServiceNavigation;
        }
        //Sonst den Default verwenden
        return true;
    }

    private get AppVersion() {
        return process.env.VUE_APP_VERSION;
    }

    /** Liefert das App-Environemnt
     * @remarks Folgende Werte werden typischerweise angezeigt:
     * "devel" oder "tool" wenn bei uns auf dem "PL" oder Devel-Testsystem
     * "reference" wenn installiert auf dem Kunden-Refernzsystem
     * "acceptance" wenn installiert auf dem Kunden-Abnahmesystem
     * "production" wenn installiert auf dem produktiven System
     * ("production" wird in der Anzeige unterdrückt werden)
     */
    private get AppEnviroment() {
        return this.vueAppEnvironment;
    }
}
