import Vue from 'vue';
import Vuex, { createLogger } from 'vuex';

Vue.use(Vuex);

// RootState
export interface IRootState {
    version: string;
}
export const RootState: IRootState = {
    version: '0.0.1'
};

import resource, { NAME as RESOURCE } from '@/store/modules/resource';
import validation, { NAME as VALIDATION } from '@/store/modules/validation';
import environment, { NAME as ENVIRONMENT } from '@/store/modules/environment';
import headerNotification, { NAME as HEADERNOTIFICATION } from '@/store/modules/headerNotification/';
import saveIndicator, { NAME as SAVEINDICATOR } from '@/store/modules/saveIndicator/';
import loadingSpinner, { NAME as LOADINGSPINNER } from '@/store/modules/loadingSpinner';
import footerBar, { NAME as FOOTERBAR } from '@/store/modules/footerBar';

// AREAS
import entry, { NAME as ENTRY } from '@/areas/entry/store';
import account, { NAME as ACCOUNT } from '@/areas/account/store';
import swisstopo, { NAME as SWISSTOPO } from '@/areas/swisstopo/store';

export const store = new Vuex.Store({
    modules: {
        [ENVIRONMENT]: environment,
        [RESOURCE]: resource,
        [VALIDATION]: validation,
        [HEADERNOTIFICATION]: headerNotification,
        [SAVEINDICATOR]: saveIndicator,
        [LOADINGSPINNER]: loadingSpinner,
        [FOOTERBAR]: footerBar,

        [ENTRY]: entry,
        [ACCOUNT]: account,
        [SWISSTOPO]: swisstopo
    },
    state: RootState,
    plugins: [
        createLogger({
            logActions: true,
            logMutations: false
        })
    ]
});

export default store;
