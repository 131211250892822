import { IRootState } from '@/store';
import { ActionTree } from 'vuex';
import { IValidationState } from './state';

import { SET_RULES } from './mutations';
import { validationService } from '@/services/validationService';

export const LOAD_VALIDATION = 'loadValidations';

export const actions: ActionTree<IValidationState, IRootState> = {
    // eslint-disable-next-line
    async [LOAD_VALIDATION]({ commit, state }: any) {
        return validationService.getAll().then(response => {
            commit(SET_RULES, response.data);
        });
    }
};
