
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';

import { namespace } from 'vuex-class';

import { NAME as NAME_LOADINGSPINNER } from '@/store/modules/loadingSpinner';
const loadingSpinnerModul = namespace(NAME_LOADINGSPINNER);
import { GET_VISIBLE, GET_TEXT, GET_DELAY } from '@/store/modules/loadingSpinner/getters';
import { HIDE } from '@/store/modules/loadingSpinner/actions';

@Component
export default class LoadingSpinner extends Vue {
    @loadingSpinnerModul.Getter(GET_VISIBLE) visible!: boolean;
    @loadingSpinnerModul.Getter(GET_TEXT) text!: string;
    @loadingSpinnerModul.Getter(GET_DELAY) delay!: number;
    @loadingSpinnerModul.Action(HIDE) hide!: Function;

    private timeOutChanges = [];
    private effectiveVisible = false;

    private get cssClass() {
        const baseCss = 'waitOverlay';
        if (this.effectiveVisible) {
            return baseCss + ' loading';
        }
        return baseCss;
    }

    @Watch('visible')
    onVisibleChanged(newVal) {
        if (newVal) {
            this.timeOutChanges.push(
                setTimeout(() => {
                    this.effectiveVisible = true;
                }, this.delay)
            );
            return;
        }
        // clear the timeoutFunction if not called
        if (this.timeOutChanges && this.timeOutChanges.length > 0) {
            for (let index = 0, length = this.timeOutChanges.length; index < length; ++index) {
                clearTimeout(this.timeOutChanges[index]);
            }
        }

        this.effectiveVisible = false;
    }
}
