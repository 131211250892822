import { IRootState } from '@/store';
import { GetterTree } from 'vuex';
import { IValidationState } from './state';

export const GET_VALIDATION_KEY = 'getValidationWithKey';

export const getters: GetterTree<IValidationState, IRootState> = {
    /** Liefert eine Validation-Rule anhand des Schlüssels.
     * @remarks Eine nicht gefundene Rule wird als "null" zurückgegeben,
     * eine effektiv leere Rule als eben solche.
     */
    [GET_VALIDATION_KEY]: state => (key: string): string | null => {
        const validation = state.validationRules[key];
        //console.debug('GET_VALIDATION_KEY:' + key, validation);
        return validation;
    }
};
