
import Vue from 'vue';
import { ValidationObserver } from 'vee-validate';
import { namespace } from 'vuex-class';
import { Component } from 'vue-property-decorator';
import { ENTRY_WIZARD_CENSUS, ENTRY_WIZARD_ENTERPRISE } from '@/router';

import { NAME } from '../store/index';
const modul = namespace(NAME);

//Components
import LocalUnitEditActivity from './LocalUnitEditActivity.vue';

//Getters und Actions
import { CASE_FULL_DATA, CASE_ACIVITY, CASE_LOCAL_UNIT } from '../store/getters';
import {
    ICaseFullDataViewModel,
    CaseStepInfoViewModel,
    ILocalUnitDataObject,
    IActivityDataObject
} from '@/api-viewmodel';
import { ActivityStepIndex } from '../views/Wizard.vue';
import StepHeader from './StepHeader.vue';

//Footer-Bar und -Buttons
import { NAME as NAME_FOOTERBAR } from '@/store/modules/footerBar';
const footerBarModul = namespace(NAME_FOOTERBAR);
import { NEW_BUTTON, CLOSE, DISABLE_BUTTONRIGHT } from '@/store/modules/footerBar/actions';
import { FooterButtonType, IFooterButton } from '@/store/modules/footerBar/state';
import { SUBMIT_CASE_STEPINFO } from '../store/actions';

import FooterBarRightButtonProxy from '@/components/FooterBarRightButtonProxy.vue';
@Component({
    components: { StepHeader, LocalUnitEditActivity, FooterBarRightButtonProxy, ValidationObserver }
})

/** Angaben zur Tätigkeit (eines Betriebs/LocalUnit im Entry Wizard)
 * @remarks Implementiert den
 *  {@link:https://collaboration.nowhow.ch/erst/foswiki/bin/view/Erst/DatenErfassungLocalTaetigkeitScreen}
 */
export default class StepActivity extends Vue {
    //Getters
    @modul.Getter(CASE_FULL_DATA) caseFullData!: ICaseFullDataViewModel;
    @modul.Getter(CASE_ACIVITY) activity!: IActivityDataObject;
    @modul.Getter(CASE_LOCAL_UNIT) localUnit!: ILocalUnitDataObject;

    //Actions
    @modul.Action(SUBMIT_CASE_STEPINFO) submitStepInfo!: Function;
    @footerBarModul.Action(NEW_BUTTON) newFooterBar!: Function;
    @footerBarModul.Action(CLOSE) closeFooterBar!: Function;
    @footerBarModul.Action(DISABLE_BUTTONRIGHT) disableButtonRight!: Function;

    mounted() {
        console.debug('StepActivity.vue::mounted');

        //Den aktuellen Step sofort melden
        this.submitStepInfo({
            caseId: this.caseFullData.caseId,
            data: new CaseStepInfoViewModel(ActivityStepIndex, null)
        });

        this.showStepNavigation();
    }

    /** Definiert die Wizard-Navigations-Buttons und zeigt diese an */
    showStepNavigation() {
        console.debug('StepActivity.vue::showStepNavigation');
        const buttonLeft: Array<IFooterButton> = [
            {
                onClick: () => {
                    this.gotoPreviousStep();
                },
                label: 'btn_wzrd_back',
                type: FooterButtonType.secondary
            }
        ];
        const buttonRight: Array<IFooterButton> = [
            {
                onClick: () => {
                    this.gotoNextStep();
                },
                label: 'btn_wzrd_next',
                type: FooterButtonType.primary
            }
        ];
        this.closeFooterBar();
        this.newFooterBar({ buttonLeft, buttonRight });
    }

    /** Navigiert zum vorangehenden Step im Entry-Wizard. */
    gotoPreviousStep() {
        this.$router.push({ name: ENTRY_WIZARD_ENTERPRISE });
    }

    /** Navigiert zum nächsten Step im Entry-Wizard. */
    gotoNextStep() {
        this.$router.push({ name: ENTRY_WIZARD_CENSUS });
    }
}
