import { IRootState } from '@/store';
import { ActionTree } from 'vuex';
import { IFooterBarState, IFooterButton } from './state';

import {
    SET_TEXT,
    SET_BAR_VISIBLE,
    SET_BUTTONLEFT,
    SET_BUTTONCENTER,
    SET_BUTTONRIGHT,
    SET_BUTTONRIGHT_DISABLED
} from './mutations';

export const NEW_TEXT = 'new_text';
export const NEW_BUTTON = 'new_button';
export const CLOSE = 'close';
export const SET_VISIBLE = 'setVisible';

/** Legt den Disabled-State der rechts liegenden Buttons auf den übergebenen Wert fest
 * @param {boolean} disabled - Ob die Buttons disabled dargestellt werden sollen
 */
export const DISABLE_BUTTONRIGHT = 'disableButtonRight';

/**
 * Funktion um die Position des Footers neu zu setzen, ist in nowhow-common.js definiert
 */
function repositionFooter() {
    // eslint-disable-next-line no-prototype-builtins
    if (window.hasOwnProperty('stickyFooterFunction')) {
        window['stickyFooterFunction'].call();
    }
}

export const actions: ActionTree<IFooterBarState, IRootState> = {
    async [NEW_TEXT]({ commit }: any, { text }: any) {
        await commit(SET_BAR_VISIBLE, true);
        commit(SET_TEXT, text);
        repositionFooter();
    },
    async [NEW_BUTTON]({ commit }: any, { buttonLeft, buttonCenter, buttonRight }: any) {
        await commit(SET_BAR_VISIBLE, true);
        commit(SET_BUTTONLEFT, buttonLeft);
        commit(SET_BUTTONCENTER, buttonCenter);
        commit(SET_BUTTONRIGHT, buttonRight);
        repositionFooter();
    },

    async [CLOSE]({ commit }) {
        commit(SET_BAR_VISIBLE, false);
        commit(SET_TEXT, 'placeholder');
        commit(SET_BUTTONLEFT, new Array<IFooterButton>());
        commit(SET_BUTTONCENTER, new Array<IFooterButton>());
        commit(SET_BUTTONRIGHT, new Array<IFooterButton>());
    },
    async [DISABLE_BUTTONRIGHT]({ commit }: any, disabled: boolean) {
        console.debug('footerBar/actions.ts::DISABLE_BUTTONRIGHT', disabled);
        commit(SET_BUTTONRIGHT_DISABLED, disabled);
    },
    async [SET_VISIBLE]({ commit }: any, isVisible: boolean) {
        console.debug('footerBar/actions.ts::SET_BAR_VISIBLE', isVisible);
        commit(SET_BAR_VISIBLE, isVisible);
    }
};
