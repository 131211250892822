import {
    ICaseFullDataViewModel,
    ICaseContactDataViewModel,
    ICaseApprovalDto,
    ICaseStepInfoViewModel,
    IEnterpriseUnitDataObject,
    ILocalUnitDataObject,
    IActivityDataObject
} from '@/api-viewmodel';
import { IEntryService } from './IEntryService';
import BaseService, { axiosCancelToken } from '@/services/baseService';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

/**
 * Implementiert den Service für Aktionen (im Entry-Wizard) auf einem Fall
 * @class
 * @implements {IEntryService}
 */
export class EntryService extends BaseService implements IEntryService {
    private ENTRY_URL: string;
    constructor() {
        super();
        this.ENTRY_URL = `${this.BASE_URL}/entry`;
    }

    /**
     * Holt die vollen Daten zum angegebenen Fall oder, falls ein Externen Benutzer den Fall bearbeitet, zum zugeordneten Fall.
     * @param {bigint} caseId - Die Id des Falles oder null, falls ein Externen Benutzer den Fall bearbeitet
     */
    async getCaseFullData(caseId: bigint): Promise<AxiosResponse<ICaseFullDataViewModel>> {
        console.debug('getCaseFullData for caseId:=' + caseId);
        if (caseId) {
            return super
                .GETbyId<AxiosResponse<ICaseFullDataViewModel>>(`${this.ENTRY_URL}/casefulldata`, caseId)
                .then((response: AxiosResponse<ICaseFullDataViewModel>) => {
                    return response;
                });
        } else {
            return super
                .GET<AxiosResponse<ICaseFullDataViewModel>>(`${this.ENTRY_URL}/casefulldata`)
                .then((response: AxiosResponse<ICaseFullDataViewModel>) => {
                    return response;
                });
        }
    }

    /**
     * Holt die Validations-Meldungen zum angegebenen Fall
     * @remarks Der API-Call behandelt selbständing den allfälligen "422 Unprocessable Entity"-Status bei vorhandenen Validierungsfehlern
     * und liefert in diesem Fall die Validierungs-Meldungen zurück.
     * @param {bigint} caseId - Die Id des Falles
     * @devdoc Die Verwendung eines HTTP-Status-Codes anders als 200 bei fehlschlagender Validierung wurde gewählt, um die Validierung hier
     * analog der Validierung beim Abschluss des Falles zu handhaben.
     */
    getCaseValidationMessages(caseId: bigint): Promise<AxiosResponse<string[]>> {
        console.debug('getCaseValidationMessages for caseId:=' + caseId);

        return super
            .GETbyId<AxiosResponse<string[]>>(
                `${this.ENTRY_URL}/ValidateCase`,
                caseId,
                this.getPostConfigToAcceptUnprocessableEntity()
            )
            .then((response: AxiosResponse<string[]>) => {
                return response;
            });
    }

    /**
     * Versendet die Kontakt-Daten zum angegebenen Fall
     * @param {bigint} params.caseId - Die Id des Falles
     * @param {ICaseContactDataViewModel} params.data - Die Kontaktdaten des Falles
     * @returns Die aktualisierten Kontakt-Daten
     * @devdoc Die aktualisierten Daten werden von der API zurückgeliefert,
     * um allfällig serverseitig erfolgte Änderungen auf den Client übernehmen zu können
     */
    submitCaseContactData(params: {
        caseId: bigint;
        data: ICaseContactDataViewModel;
    }): Promise<AxiosResponse<ICaseContactDataViewModel>> {
        console.debug('submitCaseContactData for caseId:=' + params.caseId);

        return super
            .POSTbyId<ICaseContactDataViewModel, AxiosResponse<ICaseContactDataViewModel>>(
                `${this.ENTRY_URL}/casecontactdata`,
                params.caseId,
                params.data
            )
            .then((response: AxiosResponse<ICaseContactDataViewModel>) => {
                return response;
            });
    }

    /**
     * Versendet die Daten einer Unternehmung zum angegebenen Fall
     * @param {bigint} params.caseId - Die Id des Falles
     * @param {IEnterpriseUnitDataObject} params.data - Die Daten der Unternehmnung
     * @returns CaseFullData(EntryWizardCacheObject) oder Fehler
     * @devdoc Die aktualisierten Daten werden von der API zurückgeliefert, um allfällig serverseitig erfolgte Änderungen auf den Client übernehmen zu können
     * @devdoc Um den Transfer von unnötigen Daten zu vermeiden, werden die referenzierten Objekte nicht übermittelt.
     */
    submitEnterpriseUnitData(params: {
        caseId: bigint;
        data: IEnterpriseUnitDataObject;
    }): Promise<AxiosResponse<ICaseFullDataViewModel>> {
        console.debug('submitEnterpriseUnitData for caseId:=' + params.caseId);

        return super
            .POSTbyId<IEnterpriseUnitDataObject, AxiosResponse<ICaseFullDataViewModel>>(
                `${this.ENTRY_URL}/UpdateEnterpriseUnit`,
                params.caseId,
                this.primitiveClone(params.data)
            )
            .then((response: AxiosResponse<ICaseFullDataViewModel>) => {
                return response;
            });
    }

    /**
     * Versendet die Tätigkeits-Daten einer Arbeitsstätte zum angegebenen Fall
     * @param {bigint} params.caseId - Die Id des Falles
     * @param {IActivityDataObject} params.data - Die Aktivitätsdaten
     * @returns CaseFullData(EntryWizardCacheObject) oder Fehler
     * @devdoc Die aktualisierten Daten werden von der API zurückgeliefert, um allfällig serverseitig erfolgte Änderungen auf den Client übernehmen zu können
     * @devdoc Für die Aktivitätsdaten ist im Gegensatz zu anderen Objekten keine spezielle Behandlung nötig, da keine weiteren Objekte referenziert werden.
     */
    submitLocalUnitActivityData(params: {
        caseId: bigint;
        data: IActivityDataObject;
    }): Promise<AxiosResponse<ICaseFullDataViewModel>> {
        console.debug('submitLocalUnitActivityData for caseId:=' + params.caseId);

        return super
            .POSTbyId<IActivityDataObject, AxiosResponse<ICaseFullDataViewModel>>(
                `${this.ENTRY_URL}/UpdateLocalUnitActivity`,
                params.caseId,
                params.data
            )
            .then((response: AxiosResponse<ICaseFullDataViewModel>) => {
                return response;
            });
    }

    /**
     * Versendet die Adress-Daten einer Arbeitsstätte zum angegebenen Fall
     * @param {bigint} params.caseId - Die Id des Falles
     * @param {ILocalUnitDataObject} params.data - Die Daten der Arbeitsstätte (es werden nur die Adressdaten weiterverarbeitet)
     * @remarks Enthält auch die ActivityDescription und den NOGA Code
     * @returns CaseFullData(EntryWizardCacheObject) oder Fehler
     * @devdoc Die aktualisierten Daten werden von der API zurückgeliefert, um allfällig serverseitig erfolgte Änderungen auf den Client übernehmen zu können
     * @devdoc Um den Transfer von unnötigen Daten zu vermeiden, werden die referenzierten Objekte nicht übermittelt.
     */
    submitLocalUnitAddressData(params: {
        caseId: bigint;
        data: ILocalUnitDataObject;
    }): Promise<AxiosResponse<ICaseFullDataViewModel>> {
        console.debug('submitLocalUnitAddressData for caseId:=' + params.caseId);

        return super
            .POSTbyId<ILocalUnitDataObject, AxiosResponse<ICaseFullDataViewModel>>(
                `${this.ENTRY_URL}/UpdateLocalUnitAddress`,
                params.caseId,
                this.primitiveClone(params.data)
            )
            .then((response: AxiosResponse<ICaseFullDataViewModel>) => {
                return response;
            });
    }

    /**
     * Versendet die Census-Daten einer Arbeitsstätte zum angegebenen Fall
     * @param {bigint} params.caseId - Die Id des Falles
     * @param {ILocalUnitDataObject} params.data - Die Daten der Arbeitsstätte (es werden nur die Census-Daten weiterverarbeitet)
     * @returns CaseFullData(EntryWizardCacheObject) oder Fehler
     * @devdoc Die aktualisierten Daten werden von der API zurückgeliefert, um allfällig serverseitig erfolgte Änderungen auf den Client übernehmen zu können
     * @devdoc Um den Transfer von unnötigen Daten zu vermeiden, werden die referenzierten Objekte nicht übermittelt.
     */
    submitLocalUnitCensusData(params: {
        caseId: bigint;
        data: ILocalUnitDataObject;
    }): Promise<AxiosResponse<ICaseFullDataViewModel>> {
        console.debug('submitLocalUnitCensusData for caseId:=' + params.caseId);

        return super
            .POSTbyId<ILocalUnitDataObject, AxiosResponse<ICaseFullDataViewModel>>(
                `${this.ENTRY_URL}/UpdateLocalUnitCensus`,
                params.caseId,
                this.primitiveClone(params.data)
            )
            .then((response: AxiosResponse<ICaseFullDataViewModel>) => {
                return response;
            });
    }

    /**
     * Versendet die aktuelle StepInfo zum angegebenen Fall
     * @param {bigint} params.caseId - Die Id des Falles
     * @param {IStepInfoViewModel} params.data - Die aktuelle StepInfo zum Fall
     * @returns Nur einen HTTP-Statuscode ohne Inhalt
     * @devdoc  Um den Netzwerk-Traffic klein zu halten, liefert dieser Aufruf ausser dem Status-Code keinen Inhalt zurück.
     * Ein allfällig gewünschtes Update der Falldaten muss explizit mit dem entsprechende API-Call geholt werden.
     */
    async submitStepInfo(params: { caseId: bigint; data: ICaseStepInfoViewModel }): Promise<AxiosResponse> {
        console.debug(
            'EntryService::submitStepInfo::caseId:=' + params.caseId + ';ICaseStepInfoViewModel:=',
            params.data
        );

        return super
            .POSTbyId<ICaseStepInfoViewModel, AxiosResponse>(
                `${this.ENTRY_URL}/UpdateStepInfo`,
                params.caseId,
                params.data
            )
            .then((response: AxiosResponse) => {
                return response;
            });
    }

    /** AXIOS Request-Config, welche auch einen "422 Unprocessable Entity"-Status akzeptiert.
     * @remarks Dieser Fall kann für die Valdierung verwendet werden,
     *  wo ein "422 Unprocessable Entity"-Status als mögliche Antwort erwartet wird.
     */
    private getPostConfigToAcceptUnprocessableEntity(): AxiosRequestConfig {
        const config = super.getPostConfig();

        console.debug(config);

        /** OK oder "422 Unprocessable Entity" aktzeptieren */
        config.validateStatus = function(status) {
            return (status >= 200 && status < 300) || status === 422;
        };
        return config;
    }

    /**
     * Versendet die Daten zum Abschluss des angegebenen Falles
     * @remarks Damit wird der Fall für den externen Benutzer definitiv abgeschlossen.
     * @param {bigint} params.caseId - Die Id des Falles
     * @param {ICaseApprovalDto} params.data - Die Daten zum Abschluss des Falles
     * @returns HTTP Status OK, wenn der Abschluss erfolgreich war
     */
    submitCaseApprovalData(params: { caseId: bigint; data: ICaseApprovalDto }): Promise<AxiosResponse> {
        console.debug('submitCaseApprovalData for caseId:=' + params.caseId);

        return super
            .POSTbyId<ICaseApprovalDto, AxiosResponse>(`${this.ENTRY_URL}/ApproveCase`, params.caseId, params.data)
            .then((response: AxiosResponse) => {
                return response;
            });
    }

    /** Verschiebt eine LocalUnit an eine andere Stelle in der Struktur, wobei das neue Parent eine LocalUnit innerhalb des gleichen Unternehmens ist.
     * @param {bigint} params.caseId - Die Id des Falles
     * @param {number} params.localUnitId - Die LocalUnitId der bearbeiteten LocalUnit
     * @param {number} params.parentLocalUnit - Die LocalUnitId des neuen Parents.
     * @returns CaseFullData(EntryWizardCacheObject) oder Fehler
     */
    moveToParentLocalUnit(params: {
        caseId: bigint;
        localUnitId: number;
        parentLocalUnitId: number;
    }): Promise<AxiosResponse<ICaseFullDataViewModel>> {
        console.debug('moveToParentLocalUnit for caseId:=' + params.caseId);

        return super
            .POSTbyId<any, AxiosResponse<ICaseFullDataViewModel>>(
                `${this.ENTRY_URL}/moveToParentLocalUnit`,
                params.caseId,
                {
                    localUnitId: params.localUnitId,
                    parentLocalUnitId: params.parentLocalUnitId
                }
            )
            .then((response: AxiosResponse<ICaseFullDataViewModel>) => {
                return response;
            });
    }

    /** Verschiebt eine LocalUnit an eine andere Stelle in der Struktur, wobei das neue Parent direkt die EnterpriseUnit (Root des gleichen Unternehmens) ist.
     * @param {bigint} params.caseId - Die Id des Falles
     * @param {number} params.localUnitId - Die LocalUnitId der bearbeiteten LocalUnit
     * @param {number} params.parentEnterpriseUnitId - Die EnterpriseUnitId des neuen Parents.
     * @returns CaseFullData(EntryWizardCacheObject) oder Fehler
     */
    moveToParentEnterpriseUnit(params: {
        caseId: bigint;
        localUnitId: number;
        parentEnterpriseUnitId: number;
    }): Promise<AxiosResponse<ICaseFullDataViewModel>> {
        console.debug('moveToParentEnterpriseUnit for caseId:=' + params.caseId);

        return super
            .POSTbyId<any, AxiosResponse<ICaseFullDataViewModel>>(
                `${this.ENTRY_URL}/moveToParentEnterpriseUnit`,
                params.caseId,
                {
                    localUnitId: params.localUnitId,
                    parentEnterpriseUnitId: params.parentEnterpriseUnitId
                }
            )
            .then((response: AxiosResponse<ICaseFullDataViewModel>) => {
                return response;
            });
    }

    /** Verschiebt eine LocalUnit (inkl. deren Hierarchie-Trail) an eine andere Stelle in der Struktur, wobei das neue Parent eine andere EnterpriseUnit als die bisherige ist (Transfer in ein anderes Unternehmens).
     * Das Ziel des Transfers ist immer direkt eine EnterpriseUnit.
     * @param {bigint} params.caseId - Die Id des Falles
     * @param {number} params.localUnitId - Die LocalUnitId der bearbeiteten LocalUnit
     * @param {number} params.destinationEnterpriseUnitId - Die EnterpriseUnitId des Ziels.
     * @returns CaseFullData(EntryWizardCacheObject) oder Fehler
     */
    transferLocalUnit(params: {
        caseId: bigint;
        localUnitId: number;
        destinationEnterpriseUnitId: number;
    }): Promise<AxiosResponse<ICaseFullDataViewModel>> {
        console.debug('transferLocalUnit for caseId:=' + params.caseId);

        return super
            .POSTbyId<any, AxiosResponse<ICaseFullDataViewModel>>(
                `${this.ENTRY_URL}/transferLocalUnit`,
                params.caseId,
                {
                    localUnitId: params.localUnitId,
                    destinationEnterpriseUnitId: params.destinationEnterpriseUnitId
                }
            )
            .then((response: AxiosResponse<ICaseFullDataViewModel>) => {
                return response;
            });
    }

    /** Die initiale Struktur wiederherstellen
     * @param {bigint} params.caseId - Die Id des Falles
     */
    resetStructure(params: { caseId: bigint }): Promise<AxiosResponse<ICaseFullDataViewModel>> {
        console.debug('resetStructure for caseId:=' + params.caseId);

        return super
            .GETbyId<AxiosResponse<ICaseFullDataViewModel>>(`${this.ENTRY_URL}/resetStructure`, params.caseId, null)
            .then((response: AxiosResponse<ICaseFullDataViewModel>) => {
                return response;
            });
    }

    /** Neues Unternehmen erstellen: Erzeugt eine neue EnterpriseUnit sowie eine zugehörige LocalUnit mit den leeren Werten. Die LocalUnit ist automatisch "Headquarter"
     * @param {bigint} params.caseId - Die Id des betreffenden Falles (zwingend, unabhängig von der Rolle des Benutzers)
     */
    createEnterpriseUnit(params: { caseId: bigint }): Promise<AxiosResponse<ICaseFullDataViewModel>> {
        console.debug('createEnterpriseUnit for caseId:=' + params.caseId);

        return super
            .POSTbyId<any, AxiosResponse<ICaseFullDataViewModel>>(
                `${this.ENTRY_URL}/createEnterpriseUnit`,
                params.caseId,
                null
            )
            .then((response: AxiosResponse<ICaseFullDataViewModel>) => {
                return response;
            });
    }

    /**  Neue Arbeitsstätte erstellen: Erstellt eine neue LocalUnit, mit leeren Angaben, zur angegebenen EnterpriseUnit.
     * @param {bigint} params.caseId - Die Id des betreffenden Falles (zwingend, unabhängig von der Rolle des Benutzers)
     * @param {number} params.enterpriseUnitId - Id der zu referenzierenden EnterpriseUnit
     */
    createLocalUnit(params: {
        caseId: bigint;
        enterpriseUnitId: number;
    }): Promise<AxiosResponse<ICaseFullDataViewModel>> {
        console.debug('createLocalUnit for caseId:=' + params.caseId);

        return super
            .POSTbyId<any, AxiosResponse<ICaseFullDataViewModel>>(`${this.ENTRY_URL}/createLocalUnit`, params.caseId, {
                enterpriseUnitId: params.enterpriseUnitId
            })
            .then((response: AxiosResponse<ICaseFullDataViewModel>) => {
                return response;
            });
    }

    /** Reaktiviert die LocalUnit mit der übergebenen Id.
     * @param {bigint} params.caseId - Die Id des betreffenden Falles (zwingend, unabhängig von der Rolle des Benutzers)
     * @param {bigint} params.localUnitId - Id der LocalUnit welche reaktiviert werden soll
     */
    reactivateLocalUnit(params: {
        caseId: bigint;
        localUnitId: number;
    }): Promise<AxiosResponse<ICaseFullDataViewModel>> {
        console.debug('reactivateLocalUnit for caseId:=' + params.caseId);

        return super
            .POSTbyId<any, AxiosResponse<ICaseFullDataViewModel>>(
                `${this.ENTRY_URL}/ReactivateLocalUnit`,
                params.caseId,
                {
                    localUnitId: params.localUnitId
                }
            )
            .then((response: AxiosResponse<ICaseFullDataViewModel>) => {
                return response;
            });
    }

    /** Deaktiviert die LocalUnit mit der übergebenen Id.
     * @param {bigint} params.caseId - Die Id des betreffenden Falles (zwingend, unabhängig von der Rolle des Benutzers)
     * @param {bigint} params.localUnitId - Id der LocalUnit welche deaktiviert werden soll
     * @param {bigint} params.deactivationReasonText - Begründung (in Prosa) für die Deaktivierung
     */
    deactivateLocalUnit(params: {
        caseId: bigint;
        localUnitId: number;
        deactivationReasonText: string;
    }): Promise<AxiosResponse<ICaseFullDataViewModel>> {
        console.debug('deactivateLocalUnit for caseId:=' + params.caseId);

        return super
            .POSTbyId<any, AxiosResponse<ICaseFullDataViewModel>>(
                `${this.ENTRY_URL}/deactivateLocalUnit`,
                params.caseId,
                {
                    localUnitId: params.localUnitId,
                    deactivationReasonText: params.deactivationReasonText
                }
            )
            .then((response: AxiosResponse<ICaseFullDataViewModel>) => {
                return response;
            });
    }

    /**  Löscht die LocalUnit mit der übergebenen Id. Ist die LocalUnit im Status neu, so wird sie definitiv gelöscht. Ansonsten in den Status "Gelöscht"
     * @param {bigint} params.caseId - Die Id des betreffenden Falles (zwingend, unabhängig von der Rolle des Benutzers)
     * @param {bigint} params.localUnitId - Id der LocalUnit welche deaktiviert werden soll
     * @param {bigint} params.deleteReasonText - Begründung (in Prosa) für die Löschung
     * @param {bigint} params.deleteReason - Der Löschgrund
     */
    deleteLocalUnit(params: { caseId: bigint; localUnitId: number; deleteReasonText: string; deleteReason: number }) {
        console.debug('deleteLocalUnit for caseId:=' + params.caseId);

        return super
            .POSTbyId<any, AxiosResponse<ICaseFullDataViewModel>>(`${this.ENTRY_URL}/deleteLocalUnit`, params.caseId, {
                localUnitId: params.localUnitId,
                deleteReasonText: params.deleteReasonText,
                deleteReason: params.deleteReason
            })
            .then((response: AxiosResponse<ICaseFullDataViewModel>) => {
                return response;
            });
    }

    /** Fusioniert die beiden EnterpriseUnits mit den angegebenen Ids. Dabei werden die Arbeitsstätten von der
     * Quell-EnterpriseUnit an die Destination-EnterpriseUnit umgehängt.
     * @param {bigint} params.caseId - Die Id des betreffenden Falles (zwingend, unabhängig von der Rolle des Benutzers)
     * @param {number} params.sourceEnterpriseUnitId - Die Id der Quell-EnterpriseUnit
     * @param {number} params.destinationEnterpriseUnitId - Die Id der Ziel-EnterpriseUnit
     */
    fusionEnterpriseUnit(params: {
        caseId: bigint;
        sourceEnterpriseUnitId: number;
        destinationEnterpriseUnitId: number;
    }) {
        console.debug('fusionEnterpriseUnit for caseId:=' + params.caseId);

        return super
            .POSTbyId<any, AxiosResponse<ICaseFullDataViewModel>>(
                `${this.ENTRY_URL}/fusionEnterpriseUnit`,
                params.caseId,
                {
                    sourceEnterpriseUnitId: params.sourceEnterpriseUnitId,
                    destinationEnterpriseUnitId: params.destinationEnterpriseUnitId
                }
            )
            .then((response: AxiosResponse<ICaseFullDataViewModel>) => {
                return response;
            });
    }

    /** Reaktiviert die EnterpriseUnit mit der übergebenen Id, indem deren Status auf Changed gesetzt wird.
     * Eventuell zugehörige LocalUnits werden ebenfalls aktiviert.
     * @param {bigint} params.caseId - Die Id des betreffenden Falles (zwingend, unabhängig von der Rolle des Benutzers)
     * @param {number} params.enterpriseUnitId -  ID der EnterpriseUnit welche reaktiviert werden soll
     */
    reactivateEnterpriseUnitWithLocals(params: { caseId: bigint; enterpriseUnitId: number }) {
        console.debug('reactivateEnterpriseUnitWithLocals for caseId:=' + params.caseId);

        return super
            .POSTbyId<any, AxiosResponse<ICaseFullDataViewModel>>(
                `${this.ENTRY_URL}/ReactivateEnterpriseUnitWithLocals`,
                params.caseId,
                {
                    enterpriseUnitId: params.enterpriseUnitId
                }
            )
            .then((response: AxiosResponse<ICaseFullDataViewModel>) => {
                return response;
            });
    }

    /** Reaktiviert die EnterpriseUnit mit der übergebenen Id, indem deren Status auf Changed gesetzt wird.
     * Eventuell zugehörige LocalUnits werden nicht bearbeitet.
     * @param {bigint} params.caseId - Die Id des betreffenden Falles (zwingend, unabhängig von der Rolle des Benutzers)
     * @param {number} params.enterpriseUnitId -  ID der EnterpriseUnit welche reaktiviert werden soll
     */
    reactivateEnterpriseUnitOnly(params: { caseId: bigint; enterpriseUnitId: number }) {
        console.debug('reactivateEnterpriseUnitOnly for caseId:=' + params.caseId);

        return super
            .POSTbyId<any, AxiosResponse<ICaseFullDataViewModel>>(
                `${this.ENTRY_URL}/ReactivateEnterpriseUnitOnly`,
                params.caseId,
                {
                    enterpriseUnitId: params.enterpriseUnitId
                }
            )
            .then((response: AxiosResponse<ICaseFullDataViewModel>) => {
                return response;
            });
    }

    /** Löscht die EnterpriseUnit mit der angegebenen Id, inklusive deren zugehörigen LocalUnits.
     * @param {bigint} params.caseId - Die Id des betreffenden Falles (zwingend, unabhängig von der Rolle des Benutzers)
     * @param {number} params.enterpriseUnitId - Id der zu löschenden EnterpriseUnit
     * @param {bigint} params.deleteReasonText - Begründung (in Prosa) für die Löschung (wird auch für die enthaltenen Local Units verwendet)
     * @param {number} params.deleteReason - Der Löschgrund (wird auch für die enthaltenen Local Units verwendet)
     */
    deleteEnterpriseUnit(params: {
        caseId: bigint;
        enterpriseUnitId: number;
        deleteReasonText: string;
        deleteReason: number;
    }) {
        console.debug('deleteEnterpriseUnit for caseId:=' + params.caseId);

        return super
            .POSTbyId<any, AxiosResponse<ICaseFullDataViewModel>>(
                `${this.ENTRY_URL}/deleteEnterpriseUnit`,
                params.caseId,
                {
                    enterpriseUnitId: params.enterpriseUnitId,
                    deleteReasonText: params.deleteReasonText,
                    deleteReason: params.deleteReason
                }
            )
            .then((response: AxiosResponse<ICaseFullDataViewModel>) => {
                return response;
            });
    }

    /** Erstellt einen shallow Clone, ohne referenzierte Objekte (bzw. nur mit den primitven Typen befüllt)
     * @remarks Kann für das Versenden von DTO's ohne Objekt-Referenzen verwendet werden
     * @devdoc shallow copy, dann Objekt-Referenzen explizit entfernen
     */
    private primitiveClone<T>(source: T): T {
        const dto = Object.assign({}, source);
        for (const key in dto) {
            if (typeof dto[key] === 'object') {
                dto[key] = null;
            }
        }
        return dto;
    }
}

export const entryService = new EntryService();
