
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import axios from 'axios';

@Component({})

/** Ermöglicht die Darstellung eines teils einer HTML-Seite als Teil einer Host-Seite
 * @remarks Diese Komponente ermöglicht Funktionalität ähnlich einem iFrame, jedoch ohne dessen Beschränkungen
 * aufgrund des Sandboxing, und mit Auswahl eines spezifischen Teils der Seite.
 */
export default class UnFrame extends Vue {
    /** Das einzufügende HTML-Element aus der erhaltenen Seite */
    @Prop({ required: false, default: null, type: HTMLElement }) private content!: HTMLElement;

    /** Die URL der abzuholenden HTML-Seite */
    @Prop({ required: true, default: '', type: String }) private url!: string;

    /** Die Id des darzustellenen HTML-Elements */
    @Prop({ required: true, default: '', type: String }) private selectElementId!: string;

    mounted() {
        //Inhalt vom Backend abholen...
        console.log('UnFrame.vue::mounted::Url', this.url);
        axios
            .get(this.url, {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            })
            .then(response => {
                const doc = new DOMParser().parseFromString(response.data, 'text/html');
                const htmlContent = doc.getElementById(this.selectElementId);
                //Inhalt einfügen
                this.$el.appendChild(htmlContent);

                //Fertig melden
                this.$emit('complete', this.selectElementId);
            });
    }
}
