import { IRootState } from '@/store';
import { ActionTree } from 'vuex';
import { IResourceState } from './state';

import { localize } from 'vee-validate';

import { LANGUAGE, NEW_LOADED_LANGUAGE } from './mutations';
import { resourceService } from '@/services/resourceService';
import { HIDE, SHOWDELAYED } from '../loadingSpinner/actions';

export const INITIALIZE_LANGUAGE = 'initializeLanguage';
export const LOAD_LANGUAGE = 'loadLanguage';
export const SET_LANGUAGE = 'setLanguage';

/** Pfad zu der Loading-Spinner-Komponente */
const LOADING_SPINNER = 'loadingSpinner/';

export const actions: ActionTree<IResourceState, IRootState> = {
    // eslint-disable-next-line
    async [INITIALIZE_LANGUAGE]({ dispatch, state }: any) {
        return dispatch(SET_LANGUAGE, state.language);
    },
    async [LOAD_LANGUAGE]({ commit, dispatch, state }: any, language: string) {
        // If the language was already loaded
        if (state.loadedLanguages.includes(language)) {
            return Promise.resolve(commit(LANGUAGE, language));
        }
        dispatch(LOADING_SPINNER + SHOWDELAYED, { delay: 1000 }, { root: true });
        return resourceService.get().then(response => {
            dispatch(LOADING_SPINNER + HIDE, {}, { root: true });
            commit(NEW_LOADED_LANGUAGE, { language: language, value: response.data });
        });
    },

    async [SET_LANGUAGE]({ commit, dispatch }: any, language: string) {
        //Setzen der Sprache auf dem Server, wird in Session gespeichert
        await resourceService.setLanguage(language);
        await dispatch(LOAD_LANGUAGE, language);

        return Promise.resolve(commit(LANGUAGE, language));
    }
};
