
import Vue from 'vue';

import { Component, Prop } from 'vue-property-decorator';

/** Ein simple Textzeile mit Label, welche anstelle eines Formular-Feldes verwendet werden kann.
 * @remarks Ermöglicht die Read-Only-Darstellung von Informationen im Stil ählich von Eingabefeldern.
 */
@Component
export default class NowhowTextline extends Vue {
    @Prop({ required: true, type: String }) private id!: string;

    /** Text für das Label
     * @remarks Als default wird ein Leerzeichen, als Platzhalter, verwendet
     */
    @Prop({ required: false, type: String, default: ' ' }) private label!: string;

    /** Text für das Textfeld
     * @remarks Als default wird ein Leerzeichen, als Platzhalter, verwendet
     * @type Kann sowohl ein String oder eine Number sein. Die Number wird als einfacher Text ausgegeben.
     */
    @Prop({ required: false, type: [String, Number], default: ' ' }) private text!: string | number;

    /** Ob das Textfeld als formatiertes HTML gerendert werden soll
     * @remarks Falls ja, wird der Text direkt als HTML ausgegeben. Dies kann bei vor-formatierten Ressourcen-Texten verwendet werden.
     */
    @Prop({ required: false, type: Boolean, default: false }) private isHtmlText!: boolean;

    @Prop({ required: false, type: Boolean, default: false }) private disabled!: boolean;
}
