
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { NAME as NAME_RESOURCE } from '@/store/modules/resource';
const resourceModul = namespace(NAME_RESOURCE);
import { INITIALIZE_LANGUAGE } from '@/store/modules/resource/actions';

import { NAME as NAME_VALIDATION } from '@/store/modules/validation';
const validationModul = namespace(NAME_VALIDATION);
import { LOAD_VALIDATION } from '@/store/modules/validation/actions';

import { NAME as NAME_ACCOUNT } from '@/areas/account/store';
const accountModul = namespace(NAME_ACCOUNT);
import { LOGEDIN_CHECK } from '@/areas/account/store/actions';

import { NAME as NAME_ENVIRONMENT } from '@/store/modules/environment';
const environmentModul = namespace(NAME_ENVIRONMENT);
import { GET_ENVIRONMENT } from '@/store/modules/environment/actions';

import Layout from './components/Layout.vue';
@Component({
    components: {
        Layout
    }
})
export default class App extends Vue {
    @resourceModul.Action(INITIALIZE_LANGUAGE) initializeLanguage!: Function;
    @validationModul.Action(LOAD_VALIDATION) loadValidation!: Function;
    @accountModul.Action(LOGEDIN_CHECK) logedinCheck!: Function;
    @environmentModul.Action(GET_ENVIRONMENT) getEnvironment!: Function;

    async created() {
        console.log('App.vue::created');
        await this.getEnvironment();
        await this.logedinCheck();
        this.loadValidation();
    }
}
