import BaseService from '@/services/baseService';
import { IResourceService } from './IResourceService';

export default class ResourceService extends BaseService implements IResourceService {
    private RESOURCE_URL: string;
    constructor() {
        super();
        this.RESOURCE_URL = `${this.BASE_URL}/ressourcen`;

        console.debug('ResourceService::constructor', this.RESOURCE_URL);
    }
    // eslint-disable-next-line
    async get(): Promise<any> {
        // eslint-disable-next-line
        return super.GET(`${this.RESOURCE_URL}/GetResourceList`).then(response => {
            return response;
        });
    }

    setLanguage(language: string): Promise<any> {
        // eslint-disable-next-line
        return super.POST(`${this.RESOURCE_URL}/SetLanguage`, { languageCode: language }).then((response: any) => {
            return response;
        });
    }
}

export const resourceService = new ResourceService();
