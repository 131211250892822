import { MutationTree } from 'vuex';
import { IFooterBarState, IFooterButton } from './state';

/** Legt die Sichtbarkeit der Footer-Bar fest */
export const SET_BAR_VISIBLE = 'setBarVisible';
export const SET_TEXT = 'setText';
export const SET_CLOSEAFTERDELAY = 'setCloseAfterDelay';
export const SET_DISMISSIBLE = 'setDismissible';
export const SET_BUTTONLEFT = 'setButtonLeft';
export const SET_BUTTONCENTER = 'setButtonCenter';
export const SET_BUTTONRIGHT = 'setButtonRight';

/** Legt den Disabled-State der rechts liegenden Buttons auf den übergebenen Wert fest
 * @param {boolean} disabled - Ob die Buttons disabled dargestellt werden sollen
 */
export const SET_BUTTONRIGHT_DISABLED = 'setButtonRightDisabled';

export const mutations: MutationTree<IFooterBarState> = {
    [SET_BAR_VISIBLE]: (state, visible: boolean) => {
        state.visible = visible;
    },
    [SET_TEXT]: (state, text: string) => {
        state.text = text;
    },
    [SET_CLOSEAFTERDELAY]: (state, closeAfterDelay) => {
        state.closeAfterDelay = closeAfterDelay;

        if (!closeAfterDelay) {
            state.closeDelay = 0;
        } else {
            state.closeDelay = 5000;
        }
    },
    [SET_DISMISSIBLE]: (state, value: boolean) => {
        state.isDismissible = value;
    },
    [SET_BUTTONLEFT]: (state, value: Array<IFooterButton>) => {
        state.buttonLeft = value;
    },
    [SET_BUTTONCENTER]: (state, value: Array<IFooterButton>) => {
        state.buttonCenter = value;
    },
    [SET_BUTTONRIGHT]: (state, value: Array<IFooterButton>) => {
        state.buttonRight = value;
    },
    [SET_BUTTONRIGHT_DISABLED]: (state, value: boolean) => {
        if (state.buttonRight) {
            for (const button of state.buttonRight) {
                button.disabled = value;
            }
        }

        //HINT: Dieser "Switch" ist nötig, um den Enabled/Disabled-State der oben bearbeiteten Footerbar-Buttons" für Vue effektiv zu triggern.
        //Andernfalls wird der Status für die Buttons nicht übernommen.
        //Grund ist, dass für Properties offenbar kein "Deep"-Watch stattfindet.
        //Das Property 'visible' ist in der Komponente direkt verfügbar, deshalb wird das beachtet
        //TODO: Das Problem sollte recherchiert werden und dann mittels "Deep-Watch" gelöst werden
        state.visible = !state.visible;
        state.visible = !state.visible;
    }
};
