import { IRootState } from '@/store';
import { state } from '@/store/modules/resource/state';
import { GetterTree } from 'vuex';
import { IAccountState } from './state';

/**Der für den Benutzer (via Login) authentisierte Benutzername */
export const AUTHENTICATED_USERNAME = 'authenticatedUsername';

/** Die für Benutzer (via Login) authorisierten Rollen */
export const AUTHORIZED_ROLES = 'authorizedRoles';

/** Gibt an, ob es sich beim aktuell eingeloggten User um einen externen Benutzer handelt (Hat Rolle 'ExternalUser')
 * @remarks Die Rollen für Externe Benutzer und BFS-Benutzer werden sich gegenseitig ausschliessend vergeben,
 * somit kann ein Externer Benutzer nie gleichzeitig ein BFS-Benutzer sein.
 */
export const IS_EXTERNAL_USER = 'isExternalUser';

/** Die Kontakt-Informationen */
export const CONTACT_INFO = 'contactInfo';

/** Ist der Benutzer angemeldet */
export const IS_LOGGED_IN = 'isLoggedIn';

/** Das Resultat (Erfolg) einer Registration */
export const REGISTRATION_RESULT = 'registrationResult';

/** Der Name der Survey, auf die sich der Benuzter registriert hat
 * @remarks wird bei der Registration verwendet */
export const SURVEY_NAME = 'surveyName';

/** Ob dem AccessCode des Benutzers eine Survey zugeordnet werden konnte
 * @remarks wird bei der Registration verwendet */
export const IS_SURVEY_NAME_ASSIGNED = 'isSurveyNameAssigned';

/** Die Anwendungs-Rolle "ExternerBenutzer"
 * @remarks Implementiert die technische Rollenbezeichnung gemäss
 * https://collaboration.nowhow.ch/erst/foswiki/bin/view/Erst/RollenFormDomain
 */
export const ROLE_EXTERNALUSER = 'ExternalUser';

export const getters: GetterTree<IAccountState, IRootState> = {
    [AUTHENTICATED_USERNAME]: state => state.userProfile.username,
    [AUTHORIZED_ROLES]: state => state.userProfile.roles,

    /** Gibt an, ob der Benutzer ein externer Benutzer ist.
     * @remarks Aufgrund von Bug#15422 wird als Fallback zusätzlich auch
     *  das nicht-vorhandensein der Rollen als externer Benutzer-Rolle gewertet.
     */
    [IS_EXTERNAL_USER]: state =>
        state.userProfile.roles.includes(ROLE_EXTERNALUSER) || !(state?.userProfile?.roles.length > 0),
    [CONTACT_INFO]: state => state.contactInfo,
    [IS_LOGGED_IN]: state => state.isLoggedIn,
    [SURVEY_NAME]: state => state.registrationInfo.registeredSurveyName,
    [IS_SURVEY_NAME_ASSIGNED]: state => (state.registrationInfo.registeredSurveyName ?? '').length > 0,
    [REGISTRATION_RESULT]: state => state.registrationResult
};
