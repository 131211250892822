export interface IHeaderNotificationState {
    visible: boolean;
    type: string;
    text: string;
    closeAfterDelay: boolean;
    closeDelay: number;
    isDismissible: boolean;
}

export const state: IHeaderNotificationState = {
    visible: false,
    type: 'danger',
    text: 'placeholder',
    closeAfterDelay: false,
    closeDelay: 0,
    isDismissible: true
};
