import { IRootState } from '@/store';
import { GetterTree } from 'vuex';
import { ISaveIndicationState } from './state';

/** Gibt an, ob der Indikator aktiv ist */
export const GET_ACTIVE = 'GET_ACTIVE';

export const getters: GetterTree<ISaveIndicationState, IRootState> = {
    [GET_ACTIVE]: state => state.active
};
