import BaseService from './baseService';

export class ValidationService extends BaseService {
    private RESOURCE_URL: string;
    constructor() {
        super();
        this.RESOURCE_URL = `${this.BASE_URL}/validation`;

        console.debug('ValidationService::constructor', this.RESOURCE_URL);
    }

    async getAll(): Promise<any> {
        return super.GET(`${this.RESOURCE_URL}/GetValidationList`).then((response: any) => {
            return response;
        });
    }
}

export const validationService = new ValidationService();
