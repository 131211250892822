
//Imports für dieses Modul
import Vue from 'vue';
import { namespace } from 'vuex-class';
import { Component } from 'vue-property-decorator';
import { NAME } from '../store/index';
import { NAME as NAME_ACCOUNT } from '@/areas/account/store';

const modul = namespace(NAME);
const accountModul = namespace(NAME_ACCOUNT);

//Getters und Actions
import { GET_CONTACT_INFO } from '../store/actions';
import { CONTACT_INFO } from '../store/getters';
import { IS_LOGGED_IN } from '@/areas/account/store/getters';

@Component

/** Kontaktangaben für die Anwendung
 * @remarks Implementiert {@link https://collaboration.nowhow.ch/erst/foswiki/bin/view/Erst/KontaktScreen}
 * */
export default class AccountContact extends Vue {
    @modul.Getter(CONTACT_INFO) contactInfo!: string;
    @modul.Action(GET_CONTACT_INFO) getContactInfo!: Function;
    @accountModul.Getter(IS_LOGGED_IN) loggedIn!: boolean;

    /** Kontaktdaten erst bei 'mounted' laden.
     * Die Kontaktdaten müssen nur geladen werden, wenn diese auch angezeigt werden. */
    mounted() {
        this.getContactInfo();
    }

    goBack() {
        window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
    }
}
