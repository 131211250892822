import { IRootState } from '@/store';
import { GetterTree } from 'vuex';
import { ILoadingSpinnerState } from './state';

export const GET_VISIBLE = 'getVisible';
export const GET_DELAY = 'getDelay';
export const GET_TEXT = 'getText';

export const getters: GetterTree<ILoadingSpinnerState, IRootState> = {
    [GET_VISIBLE]: state => state.visible,
    [GET_DELAY]: state => state.delay,
    [GET_TEXT]: state => state.text,

};

