import { IRootState } from '@/store';
import { ActionTree } from 'vuex';
import { IEnvironmentState } from './state';
import { environmentService } from '@/services/EnvironmentService';
import { MAPENVIRONMENTINFO } from './mutations';

/** Holt die Informationen zum Environment */
export const GET_ENVIRONMENT = 'GET_ENVIRONMENT';

export const actions: ActionTree<IEnvironmentState, IRootState> = {
    [GET_ENVIRONMENT]({ commit }: any) {
        environmentService.environmentInfo().then(response => {
            commit(MAPENVIRONMENTINFO, response.data);
        });
    }
};
