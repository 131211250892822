import { IRootState } from '@/store';
import { ActionTree } from 'vuex';
import { ISwisstopoState } from './state';
import { swisstopoService } from '../services/SwisstopoService';
import { MAP_FEATURE_SEARCH_RESULT, MAP_SELECTED_FEATURE } from './mutations';
import { SHOW, HIDE } from '@/store/modules/loadingSpinner/actions';
import { CLOSE } from '@/store/modules/headerNotification/actions';

/** Pfad zu der Loading-Spinner-Komponente */
const LOADING_SPINNER = 'loadingSpinner/';

/** Pfad zu der Header-Notification-Komponente */
const HEADER_NOTIFICATION = 'headerNotification/';

/** Führt eine Suche nach Features aus.
 * @remarks Verwendet die Funktion gemäss https://collaboration.nowhow.ch/erst/foswiki/bin/view/Erst/SearchServerSwisstopoFunktion
 */
export const SEARCH_FEATURES = 'SEARCH_FEATURES';

/** Holt die Daten zu einem Feature und legt diese auf dem Store als selektiertes Feature ab.
 * @remarks Verwendet die Funktion gemäss https://collaboration.nowhow.ch/erst/foswiki/bin/view/Erst/MapServerSwisstopoFunktion
 */
export const GET_FEATURE_DETAILS = 'GET_FEATURE_DETAILS';

export const actions: ActionTree<ISwisstopoState, IRootState> = {
    /**
     * Führt die Feature-Suche anhand des angegebenen Freitexts aus und übergibt das erhaltene Suchresultat in den Zustand.
     * @param {string} searchText - Der Freitext für die Suche (max. 10 Wörter).
     * @return Eine Promise, mit der der Aufrufende Code den Erfolg feststellen und entsprechende behandeln kann
     * @devdoc Zur Promise: siehe https://stackoverflow.com/a/40167499/79485 und https://vuex.vuejs.org/guide/actions.html#composing-actions
     */
    [SEARCH_FEATURES]({ dispatch, commit }: any, searchText: string): Promise<void> {
        return new Promise((resolve, reject) => {
            dispatch(LOADING_SPINNER + SHOW, {}, { root: true });

            //Aktion durchführen und Resultat zuweisen
            swisstopoService
                .searchFeatures(searchText)
                .then(response => {
                    commit(MAP_FEATURE_SEARCH_RESULT, response.data);
                })
                .then(() => {
                    dispatch(LOADING_SPINNER + HIDE, {}, { root: true });
                })
                .then(
                    response => {
                        // http success, call the mutator and change something in state
                        resolve(response); // Let the calling function know that http is done. You may send some data back
                    },
                    error => {
                        // http failed, let the calling function know that action did not work out
                        reject(error);

                        //Allfällige Fehlermeldung sofort verstecken, diese wir für die Featuresuche gemäss Anforderung "unsichtbar" behandelt
                        dispatch(HEADER_NOTIFICATION + CLOSE, {}, { root: true });
                    }
                );
        });
    },
    [GET_FEATURE_DETAILS]({ dispatch, commit }: any, featureId: string) {
        dispatch(LOADING_SPINNER + SHOW, {}, { root: true });

        swisstopoService
            .getFeature(featureId)
            .then(response => {
                commit(MAP_SELECTED_FEATURE, response.data);
            })
            .then(() => {
                dispatch(LOADING_SPINNER + HIDE, {}, { root: true });
            });
    }
};
