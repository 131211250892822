import { IFeature, IFeatureSearchResult } from '../api-viewmodel';

/** Definiert den Zustand für eine Feature-Suche
 * @interface
 */
export interface ISwisstopoState {
    // eslint-disable-next-line lines-around-comment
    /** Das Resultat einer Feature-Suche */
    searchResult: IFeatureSearchResult;

    /** Das Daten einer Abfrage zu einem einzelnen, ausgewählten, Feature
     * @remarks Dieses Feature ist typischerweise ein ausgewähltes aus einer vorangehenden Feature-Suche
     */
    selectedFeature: IFeature;
}

/** Stellt den Zustand für eine Feature-Suche zur Verfügung.
 */
export const state: ISwisstopoState = {
    /** Das Resultat einer Suche */
    searchResult: {
        results: []
    },
    selectedFeature: null
};
