
import Vue from 'vue';
import { namespace } from 'vuex-class';
import { Component, Watch } from 'vue-property-decorator';
import { ENTRY_WIZARD_CONFIRMATION_FINISH, ENTRY_PRINTOUT, ENTRY_WIZARD_CENSUS } from '@/router';
import { GET_LANGUAGE } from '@/store/modules/resource/getters';

import NowhowTextbox from '@/components/NowhowTextbox.vue';
import NowhowTextline from '@/components/NowhowTextline.vue';
import NowhowDropdown from '@/components/NowhowDropdown.vue';
import NowhowTextarea from '@/components/NowhowTextarea.vue';
import NowhowCheckbox from '@/components/NowhowCheckbox.vue';
import NowhowRadioList from '@/components/NowhowRadioList.vue';
import NowhowModal from '@/components/NowhowModal.vue';
import { ConfirmationStepIndex } from '../views/Wizard.vue';
import StepHeader from './StepHeader.vue';

import { NAME } from '../store/index';
const modul = namespace(NAME);

import { NAME as NAME_ACCOUNT } from '@/areas/account/store';
const accountModul = namespace(NAME_ACCOUNT);

import { NAME as NAME_RESOURCE } from '@/store/modules/resource';
const resourceModul = namespace(NAME_RESOURCE);

//Getters und Actions
import { SUBMIT_CASE_STEPINFO, GET_CASE_VALIDATION_MESSAGES, SUBMIT_CASE_APPROVAL } from '../store/actions';
import { CASE_FULL_DATA, CASE_APPROVAL, CASE_CONTACT_DATA, CASE_VALIDATION_MESSAGES } from '../store/getters';
import { LOGOUT } from '@/areas/account/store/actions';
import { IS_EXTERNAL_USER } from '@/areas/account/store/getters';
import {
    ICaseFullDataViewModel,
    ICaseContactDataViewModel,
    CaseStepInfoViewModel,
    ICaseApprovalDto
} from '@/api-viewmodel';

//Footer-Bar und -Buttons
import { NAME as NAME_FOOTERBAR } from '@/store/modules/footerBar';
const footerBarModul = namespace(NAME_FOOTERBAR);
import { NEW_BUTTON, CLOSE } from '@/store/modules/footerBar/actions';
import { FooterButtonType, IFooterButton } from '@/store/modules/footerBar/state';
@Component({
    components: {
        StepHeader,
        NowhowTextbox,
        NowhowTextline,
        NowhowDropdown,
        NowhowTextarea,
        NowhowCheckbox,
        NowhowRadioList,
        NowhowModal
    }
})

/** Confirmation-Step im Entry Wizard
 * @remarks Implementiert den {@link:https://collaboration.nowhow.ch/erst/foswiki/bin/view/Erst/DatenErfassungBestaetigungScreen}
 */
export default class StepConfirmation extends Vue {
    protected WEBFORMS_BASE_URL: string | undefined = process.env.VUE_APP_WEBFORMS_BACKEND_URL;

    constructor() {
        super();
        //Hint den Fallback zentral lösen
        if (!this.WEBFORMS_BASE_URL) {
            //Fallback für den Fall dass keine Backend-URL verfügbar ist
            const url = window.location.href;
            const searchParam = 'redesign/';
            const length = url.toLowerCase().indexOf(searchParam) - 1; //ohne 'redesign/' Pfad
            this.WEBFORMS_BASE_URL = url.substr(0, length);
            console.debug('StepConfirmation::constructor (with fallback URL)', this.WEBFORMS_BASE_URL);
        } else {
            console.debug('StepConfirmation::constructor', this.WEBFORMS_BASE_URL);
        }
    }

    /** @devdoc Diese Definition wird hier zur Initialisierung benötigt */
    private ENTRY_PRINTOUT = ENTRY_PRINTOUT;

    //Getters
    @modul.Getter(CASE_FULL_DATA) caseFullData!: ICaseFullDataViewModel;
    @modul.Getter(CASE_APPROVAL) caseApproval!: ICaseApprovalDto;
    @modul.Getter(CASE_CONTACT_DATA) caseContactData!: ICaseContactDataViewModel;
    @modul.Getter(CASE_VALIDATION_MESSAGES) caseValidationMessages!: string[];
    @accountModul.Getter(IS_EXTERNAL_USER) isExternalUser!: boolean;
    @resourceModul.Getter(GET_LANGUAGE) language!: string;

    //Actions
    @modul.Action(SUBMIT_CASE_STEPINFO) submitStepInfo!: Function;

    @modul.Action(SUBMIT_CASE_APPROVAL) caseApprove!: Function;
    @modul.Action(GET_CASE_VALIDATION_MESSAGES) caseValidate!: Function;
    @accountModul.Action(LOGOUT) logout!: Function;
    @footerBarModul.Action(NEW_BUTTON) newFooterBar!: Function;
    @footerBarModul.Action(CLOSE) closeFooterBar!: Function;

    /** Ob die Confirmationmessage angezeigt wird.
     * @remarks Dies ist bei jedem erneuten Aufruf der Page immer der Fall*/
    showConfirmationMessage = false;

    mounted() {
        //Den aktuellen Step sofort melden
        this.submitStepInfo({
            caseId: this.caseFullData.caseId,
            data: new CaseStepInfoViewModel(ConfirmationStepIndex, null)
        });

        this.showStepNavigation();

        //Erstmalige serverseitige Validierung, mit den bisher auf dem Server vorhandenen Daten
        this.caseValidate(this.caseFullData.caseId);

        //Zustimmung zum Abschluss immer explizit verlangen
        this.caseApproval.consent = false;

        //Overlay immer anzeigen
        this.showConfirmationMessage = true;
    }

    /** Behandelt den Sprachwechsel, indem die serverseitige Validierung mit der neuen Sprache wiederholt wird
     * @remarks Da die serverseitige Validierung nicht client-seitig übersetzt werden kann, muss diese neu geholt werden
     * Siehe auch Bug#11856
     */
    @Watch('language', { deep: false, immediate: true })
    private onOpenJobCountNextQuarterhanged() {
        this.caseValidate(this.caseFullData.caseId);
    }

    /** Gibt an, ob (server-seitige) Validierungs-Meldungen für den Fall vorliegen. */
    get hasCaseValidationMessages(): boolean {
        //return true;
        return this.caseValidationMessages?.length > 0;
    }

    /** Wrapper für eine Form-Submit-Aktion, um das Standard-Submit-Verhalten im Zusammenspiel mit vee-validate zu unterbinden.
     * @devdoc Wird hier benötigt, da sich die zu versendenden Daten nicht im Store befinden.
     */
    private approvalWrapper() {
        this.caseApprove({
            caseId: this.caseFullData.caseId,
            data: this.caseApproval,
            onSuccess: () => {
                if (this.isExternalUser) {
                    this.logout();
                    this.gotoConfirmationFinish();
                } else {
                    this.redirectAfterSuccessfulApproval();
                }
            }
        });
    }

    closeModal() {
        this.showConfirmationMessage = false;
    }

    visibilityChanged(value) {
        this.showConfirmationMessage = value;
    }

    /** Definiert die Wizard-Navigations-Buttons und zeigt diese an */
    showStepNavigation() {
        console.debug('StepConfirmation.vue::showStepNavigation');
        const buttonLeft: Array<IFooterButton> = [
            {
                onClick: () => {
                    this.gotoPreviousStep();
                },
                label: 'btn_wzrd_back',
                type: FooterButtonType.secondary
            }
        ];
        this.closeFooterBar();
        this.newFooterBar({ buttonLeft /*, buttonRight*/ });
    }

    /** Öffnet ein neues Tab für die Print-Seite, für diesen Fall. */
    gotoPrintPage() {
        const routeData = this.$router.resolve({
            name: ENTRY_PRINTOUT,
            query: { CaseId: this.caseFullData.caseId.toString() }
        });

        console.debug('StepConfirmation.vue::gotoPrintPage::routeData', routeData);
        window.open(routeData.href, '_blank');
    }

    /** Navigiert zum vorangehenden Step im Entry-Wizard. */
    gotoPreviousStep() {
        console.debug('StepConfirmation.vue::gotoPreviousStep');
        this.$router.push({ name: ENTRY_WIZARD_CENSUS });
    }

    /** Für Externe Benutzer: Navigiert zum Finis-Screen
     */
    gotoConfirmationFinish() {
        console.debug('StepConfirmation.vue::gotoConfirmationFinish');
        this.$router.push({ name: ENTRY_WIZARD_CONFIRMATION_FINISH });
    }

    /** Für BFS-Benutzer: Navigiert in den Start-Screen im Backoffice (wie nach einem Login)
     */
    redirectAfterSuccessfulApproval() {
        console.debug('StepConfirmation.vue::redirectAfterSuccessfulApproval');
        //Die Return-URL für das klassische UI verwenden (per Default auf die interne Start-Seite)
        const location = this.WEBFORMS_BASE_URL + '/Start.aspx';
        window.location.href = location;
    }
}
