
//Imports für dieses Modul
import Vue from 'vue';
import { namespace } from 'vuex-class';
import { NAME } from '../store/index';
import { NAME as NAME_VALIDATION } from '@/store/modules/validation';
import { Component } from 'vue-property-decorator';
import NowhowTextbox from '@/components/NowhowTextbox.vue';
import { GET_VALIDATION_KEY } from '@/store/modules/validation/getters';
import { ACCOUNT_CONTACT } from '@/router';

const modul = namespace(NAME);

const validationModul = namespace(NAME_VALIDATION);

//Getters und Actions
import { RegistrationDataViewModel } from '@/api-viewmodel';
import { REGISTRATION_RESULT } from '../store/getters';

@Component({
    components: {
        NowhowTextbox
    }
})

/** Implementiert den Schritt 1 der Registrierung: Eingabe des Zugriffscodes
 * @devdoc Enthält diversen Javascript Code im Zusammenhang mit der Eingabe des Access-Codes
 */
export default class Step3Result extends Vue {
    private ACCOUNT_CONTACT = ACCOUNT_CONTACT;

    registrationData = new RegistrationDataViewModel();

    @modul.Getter(REGISTRATION_RESULT) getRegistrationResult!: boolean;

    @validationModul.Getter(GET_VALIDATION_KEY) getValidation!: (key: string) => string;

    /** Gibt an, ob die Registration erfolgreich war */
    get isRegistrationSuccessful() {
        return this.getRegistrationResult === true;
    }

    /** Gibt an, ob die Registration fehlschlug */
    get isRegistrationFailed() {
        return this.getRegistrationResult === false;
    }

    /** Gibt an, ob die Registration abgeschlossen ist */
    get isRegistrationDone() {
        return this.getRegistrationResult !== null;
    }
}
