
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';

import { namespace } from 'vuex-class';

import { NAME as NAME_HEADERNOTIFICATION } from '@/store/modules/headerNotification';
const headerNotificationModul = namespace(NAME_HEADERNOTIFICATION);
import {
    GET_VISIBLE,
    GET_TYPE,
    GET_TEXT,
    GET_CLOSEDELAY,
    GET_CLOSEAFTERDELAY,
    GET_DISMISSIBLE
} from '@/store/modules/headerNotification/getters';

import { CLOSE } from '@/store/modules/headerNotification/actions';

@Component
export default class HeaderNotifcation extends Vue {
    @headerNotificationModul.Getter(GET_VISIBLE) alertVisible!: boolean;
    @headerNotificationModul.Getter(GET_TYPE) alertType!: string;
    @headerNotificationModul.Getter(GET_TEXT) alertText!: string;
    @headerNotificationModul.Getter(GET_CLOSEDELAY) closeDelay!: number;
    @headerNotificationModul.Getter(GET_CLOSEAFTERDELAY) closeAfterDelay!: boolean;
    @headerNotificationModul.Getter(GET_DISMISSIBLE) isDismissible!: boolean;

    @headerNotificationModul.Action(CLOSE) close!: Function;

    private get cssClass() {
        const baseCss = 'alert';

        if (this.alertType === 'success') {
            return baseCss + ' alert-success';
        } else if (this.alertType === 'info') {
            return baseCss + ' alert-info';
        } else if (this.alertType === 'warning') {
            return baseCss + ' alert-warning';
        } else if (this.alertType === 'danger') {
            return baseCss + ' alert-danger';
        }

        return baseCss;
    }

    @Watch('alertVisible')
    onVisibleChangedOpen() {
        if (this.alertVisible) {
            if (this.closeAfterDelay && this.closeDelay > 0) {
                setTimeout(() => {
                    this.close();
                }, this.closeDelay);
            }
        }
    }

    alertTextLines(): string[] {
        return this.alertText.split('<br />');
    }
}
