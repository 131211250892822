import { MutationTree } from 'vuex';
import { IEnvironmentViewModel } from '@/api-viewmodel';
import { IEnvironmentState } from './state';

/** Weist Environment-Informationen zu */
export const MAPENVIRONMENTINFO = 'mapEnvironmentInfo';

export const mutations: MutationTree<IEnvironmentState> = {
    [MAPENVIRONMENTINFO](state, value: IEnvironmentViewModel) {
        state.environment = value;
    }
};
