
import Vue from 'vue';
import { namespace } from 'vuex-class';

import { NAME as NAME_VALIDATION } from '@/store/modules/validation';
const validationModul = namespace(NAME_VALIDATION);

import { Component, Prop, Watch } from 'vue-property-decorator';
import { GET_VALIDATION_KEY } from '@/store/modules/validation/getters';

@Component

/** Implementiert eine Input-Box mit kompletter Validierung für eine positive Integralzahl im 32-Bit (signed) Range (jedoch ohne Label-Element)
 * @remarks Rendert keine Grid-Elemente
 */
export default class NowhowUInt32Input extends Vue {
    /** Validierung via Validations-Modul */
    @validationModul.Getter(GET_VALIDATION_KEY) getValidation!: (key: string) => string;

    @Prop({ required: true, type: String }) private id!: string;

    /** Label für das Eingabefeld
     * @remarks Wird hier nur für die Eigenschaft 'name' auf dem Eingabefeld sowie den Validation-Provider verwendet,
     * nicht für die sichtbare Darstellung */
    @Prop({ required: false, type: String, default: '' }) private label!: string;

    @Prop({ required: false, type: String, default: '' }) private placeholder!: string;

    @Prop({ required: false, type: String, default: '' }) private autocomplete!: string;

    @Prop({ required: false, type: Boolean, default: false }) private disabled!: boolean;

    @Prop({ required: false, type: Boolean, default: false }) private readonly!: boolean;

    @Prop({ default: '', type: [String, Object] }) validate!: string | object;

    @Prop({ required: true, default: '' }) private value!: number | null;

    @Prop({ required: false, type: String, default: '' })
    private cy!: string;

    /* eslint-disable-next-line no-empty, @typescript-eslint/no-empty-function */
    @Prop({ required: false, type: Function, default: function() {} })
    private onEnter!: Function;

    private provider: any;
    private touched = false;

    private MAX_INT32 = 2147483647;
    private MIN_INT32 = -2147483648;

    mounted() {
        const inputElement: any = document.getElementById(String(this.id));
        inputElement.value = this.value;

        this.provider = this.$refs['provider'];
    }

    /** Prüft, ob der Wertebereich eingehalten wird */
    isInRange(value): boolean {
        //Leere Eingabe erlauben
        if (value === '') {
            return true;
        }

        //Ziffern erzwingen
        const isnum = /^\d+$/.test(value);
        if (!isnum) {
            //console.debug('Number is not an integer:', value);
            return false;
        }

        //Range erzwingen
        if (value > this.MAX_INT32) {
            console.debug('Number ist too large:', value);
            return false;
        } else if (value < this.MIN_INT32) {
            console.debug('Number ist too small:', value);
            return false;
        }

        return true;
    }

    public focus() {
        (this.$refs.inputfield as HTMLInputElement).focus();
    }

    private onChange(value) {
        //Nur wenn innerhalb des erlaubten Bereichs, den Wert weitergeben
        if (this.isInRange(value) === true) {
            //console.debug('emitting', value);
            this.$emit('change', value ? Number(value) : null);
            this.touched = true;
        }
    }

    private onFocusout(value) {
        this.$emit('focusout', value);
        this.touched = true;
    }

    private onInput(value) {
        //Nur wenn innerhalb des erlaubten Bereichs, den Wert weitergeben

        if (this.isInRange(value) === true) {
            //console.debug('emitting', value);
            this.$emit('input', value ? Number(value) : null);
            this.touched = true;
        }
    }

    @Watch('value', { deep: true, immediate: true })
    private onValueChanged() {
        this.provider = this.$refs['provider'];
        this.provider?.validate(this.value, { silent: true });
    }
}
