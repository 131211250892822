var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"drilldown"},[_c('div',{staticClass:"drilldown-container"},[_c('nav',{staticClass:"nav-page-list",attrs:{"id":"secondary-navigation"}},[_c('ul',[_c('li',{staticClass:"list-emphasis"},[_c('span',[_vm._v(_vm._s(_vm.$t('allg_application_name')))]),_c('ul',[_c('router-link',{attrs:{"to":{ name: _vm.ACCOUNT_LOGIN }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{class:[
                                    isActive && 'active',
                                    isExactActive && 'router-link-exact-active',
                                    'dropdown yamm-fw'
                                ]},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(_vm.$t('nav_login')))])])]}}])}),_c('router-link',{attrs:{"to":{ name: _vm.ACCOUNT_REGISTER }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{class:[
                                    isActive && 'active',
                                    isExactActive && 'router-link-exact-active',
                                    'dropdown yamm-fw'
                                ]},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(_vm.$t('nav_register')))])])]}}])})],1)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }