
//Imports für dieses Modul
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import NowhowTextbox from '@/components/NowhowTextbox.vue';
import NowhowTextline from '@/components/NowhowTextline.vue';
import NowhowDropdown from '@/components/NowhowDropdown.vue';
import NowhowTextarea from '@/components/NowhowTextarea.vue';
import NowhowCheckbox from '@/components/NowhowCheckbox.vue';
import NowhowRequiredInfoline from '@/components/NowhowRequiredInfoline.vue';
import FooterBarRightButtonProxy from '@/components/FooterBarRightButtonProxy.vue';
import { GET_VALIDATION_KEY } from '@/store/modules/validation/getters';
import { ENTRY_WIZARD_INFO, ENTRY_WIZARD_LOCAL } from '@/router';
import { ISelectListItem } from '@/types/clienttypes';
import { ContactStepIndex } from '../views/Wizard.vue';
import StepHeader from './StepHeader.vue';

import { NAME as NAME_ACCOUNT } from '@/areas/account/store';
const accountModul = namespace(NAME_ACCOUNT);

import { NAME } from '../store/index';
const modul = namespace(NAME);

import { NAME as NAME_VALIDATION } from '@/store/modules/validation';
const validationModul = namespace(NAME_VALIDATION);

//Footer-Bar und -Buttons
import { NAME as NAME_FOOTERBAR } from '@/store/modules/footerBar';
const footerBarModul = namespace(NAME_FOOTERBAR);
import { NEW_BUTTON, CLOSE } from '@/store/modules/footerBar/actions';
import { FooterButtonType, IFooterButton } from '@/store/modules/footerBar/state';

//Getters und Actions
import { ICaseContactDataViewModel, ICaseFullDataViewModel, CaseStepInfoViewModel } from '@/api-viewmodel';
import { SUBMIT_CASE_STEPINFO, SUBMIT_CASE_CONTACT_DATA } from '../store/actions';
import { CASE_FULL_DATA, CASE_CONTACT_DATA } from '../store/getters';
import { IS_EXTERNAL_USER, AUTHENTICATED_USERNAME } from '@/areas/account/store/getters';

@Component({
    components: {
        StepHeader,
        NowhowTextbox,
        NowhowTextline,
        NowhowDropdown,
        NowhowTextarea,
        NowhowCheckbox,
        NowhowRequiredInfoline,
        FooterBarRightButtonProxy
    }
})

/** Contact-Step im Entry Wizard
 * @remarks Implementiert den {@link:https://collaboration.nowhow.ch/erst/foswiki/bin/view/Erst/DatenErfassungKontaktAngabenScreen}
 */
export default class StepContact extends Vue {
    //Getters
    @modul.Getter(CASE_CONTACT_DATA) caseContactData!: ICaseContactDataViewModel;
    @modul.Getter(CASE_FULL_DATA) caseFullData!: ICaseFullDataViewModel;
    @validationModul.Getter(GET_VALIDATION_KEY) getValidation!: (key: string) => string;
    @accountModul.Getter(AUTHENTICATED_USERNAME) authenticatedUsername!: string;
    @accountModul.Getter(IS_EXTERNAL_USER) isExternalUser!: boolean;

    //Actions
    @modul.Action(SUBMIT_CASE_STEPINFO) submitStepInfo!: Function;
    @modul.Action(SUBMIT_CASE_CONTACT_DATA) submitCaseContactData!: Function;
    @footerBarModul.Action(NEW_BUTTON) newFooterBar!: Function;
    @footerBarModul.Action(CLOSE) closeFooterBar!: Function;

    mounted() {
        console.log('StepContact.vue::mounted');

        //Den aktuellen Step sofort melden
        this.submitStepInfo({
            caseId: this.caseFullData.caseId,
            data: new CaseStepInfoViewModel(ContactStepIndex, null)
        });

        this.showStepNavigation();

        //Aktuelles Token der unveränderte Daten für die spätere, erstmalige Prüfung merken
        this.lastSubmittedDataToken = this.submittableDataToken;
    }

    beforeDestroy() {
        console.log('StepContact.vue::beforeDestroy');
        this.saveStepData();
    }

    /** Behandelt die Änderung der "Keine Email" Checkbox
     * @remarks Wenn "Keine Email" ausgewählt wird, die allfällig vorhandene E-Mail-Adresse entfernen.
     * Siehe Anforderung 004 im DatenErfassungKontaktAngabenScreen
     */
    @Watch('caseContactData.emailAddressOverride', { deep: true, immediate: true })
    private onEmailAddressOverrideChanged(newVal: boolean) {
        if (newVal) {
            this.caseContactData.emailAddress = '';
        }
    }

    /** Liefert die zur Auswahl stehenden Sprachen. Per Anforderung gibt es keine leere Auswahl. Der Benutzer ist gezwungen, eine Auswahl zu treffen.
     * @remarks Gemäss Kundenwunsch stehen hier die Sprachen Rätoromanisch und Englisch nicht zur Verfügugng
     */
    dropdownLanguageItems: Array<ISelectListItem> = [
        {
            /* Deutsch */
            value: 1,
            text: 'typeLanguage.1',
            selected: false,
            disabled: false
        },
        {
            /* Französisch */
            value: 2,
            text: 'typeLanguage.2',
            selected: false,
            disabled: false
        },
        {
            /* Italienisch */
            value: 3,
            text: 'typeLanguage.3',
            selected: false,
            disabled: false
        }
    ];

    /** Liefert die zur Auswahl stehenden Anreden. Per Anforderung gibt es keine leere Auswahl. Der Benutzer ist gezwunge, eine Auswahl zu treffen. */
    dropdownSalutationItems: Array<ISelectListItem> = [
        {
            /*Herr*/
            value: 1,
            text: 'typeSalutation.1',
            selected: false,
            disabled: false
        },
        {
            /*Frau*/
            value: 2,
            text: 'typeSalutation.2',
            selected: false,
            disabled: false
        },
        {
            /*Sehr geehrte Damen und Herren*/
            value: 3,
            text: 'typeSalutation.3',
            selected: false,
            disabled: false
        }
    ];

    /** Definiert die Wizard-Navigations-Buttons und zeigt diese an */
    showStepNavigation() {
        console.debug('StepContact.vue::showStepNavigation');
        const buttonLeft: Array<IFooterButton> = [
            {
                onClick: () => {
                    this.gotoPreviousStep();
                },
                label: 'btn_wzrd_back',
                type: FooterButtonType.secondary
            }
        ];
        const buttonRight: Array<IFooterButton> = [
            {
                onClick: () => {
                    console.log('N*S');
                    this.gotoNextStep();
                },
                label: 'btn_wzrd_next',
                type: FooterButtonType.primary
            }
        ];
        this.closeFooterBar();
        this.newFooterBar({ buttonLeft, buttonRight });
    }

    /** Navigiert zum vorangehenden Step im Entry-Wizard. */
    gotoPreviousStep() {
        this.$router.push({ name: ENTRY_WIZARD_INFO });
    }

    /** Navigiert zum nächsten Step im Entry-Wizard. */
    gotoNextStep() {
        this.$router.push({ name: ENTRY_WIZARD_LOCAL });
    }

    /** Speichert die aktuell eingegebenen Werte auf das Backend
     * @remarks Die Daten werden nur versendet, wenn diese seit dem letzten Versand oder erstmalig geändert haben
     * Für diese Prüfung werden alle Eingabewerte dieser Komponente verwendet
     * @devdoc Diese Funktion soll beim Verlassen des Bearbeitungsformulars aufgerufen werden
     * Hinweis: Es kann aktuell nicht die Funktionalität der ValidationObserver-Komponente verwendet werden,
     * weil diese keinen zwischenzeitlichen Reset der "Dirty-Flags" erlaubt.
     * Hinweis2: Es wird nicht ein "dirty-tracking" via "changed"-Event implementiert, um nur zum Zeitpunkt des
     * Versandes effektiv veränderte Daten zu versenden.
     */
    saveStepData() {
        console.debug('::saveStepData');
        //Sicherstellen, dass der Override für Externe Benutzer nie erlaubt ist
        if (this.isExternalUser) {
            this.caseContactData.emailAddressOverride = false;
        }

        const submittableDataToken = this.submittableDataToken;
        if (submittableDataToken !== this.lastSubmittedDataToken) {
            this.submitCaseContactData({
                caseId: this.caseFullData.caseId,
                data: this.caseContactData
            });
            this.lastSubmittedDataToken = submittableDataToken;
        }
    }

    /** Zwischenspeicher für die Prüfung, ob ein erneuter Versand der Daten notwendig ist */
    lastSubmittedDataToken: string = null;

    /** Liefert ein Token für die versendbaren Daten zur späteren Prüfung, ob ein erneuter Versand notwendig ist.
     * @devdoc Für den effektiven Versand an die API wird das Objekt aus dem Zustand verwendet,
     *  nicht dieses interne DTO.
     */
    get submittableDataToken() {
        return JSON.stringify([
            this.caseContactData.salutationCd,
            this.caseContactData.firstName,
            this.caseContactData.lastName,
            this.caseContactData.role,
            this.caseContactData.emailAddressOverride,
            this.caseContactData.emailAddress,
            this.caseContactData.phoneNumber,
            this.caseContactData.languageCd,
            this.caseContactData.comment
        ]);
    }
}
